(function () {
    "use strict";
    angular
        .module("expedientesUriarteApp")
        .factory(
            "ConsortiumContabilidadRecibida",
            ConsortiumContabilidadRecibida
        );

    ConsortiumContabilidadRecibida.$inject = ["$resource", "DateUtils"];

    function ConsortiumContabilidadRecibida($resource, DateUtils) {
        var resourceUrl = "api/consortium-contabilidad-recibidas/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.invoiceDate) {
                                data.invoiceDate = DateUtils.convertLocalDateFromServer(
                                    data.invoiceDate
                                );
                            }
                            if (data.payDate) {
                                data.payDate = DateUtils.convertLocalDateFromServer(
                                    data.payDate
                                );
                            }
                        }
                        return data;
                    },
                },
                update: {
                    method: "PUT",
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        if (copy.invoiceDate !== null) {
                            copy.invoiceDate = DateUtils.convertLocalDateToServer(
                                copy.invoiceDate
                            );
                        }
                        if (copy.payDate !== null) {
                            copy.payDate = DateUtils.convertLocalDateToServer(
                                copy.payDate
                            );
                        }
                        return angular.toJson(copy);
                    },
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.invoiceDate) {
                                data.invoiceDate = DateUtils.convertLocalDateFromServer(
                                    data.invoiceDate
                                );
                            }
                            if (data.payDate) {
                                data.payDate = DateUtils.convertLocalDateFromServer(
                                    data.payDate
                                );
                            }
                        }
                        return data;
                    },
                },
                save: {
                    method: "POST",
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        if (copy.invoiceDate !== null) {
                            copy.invoiceDate = DateUtils.convertLocalDateToServer(
                                copy.invoiceDate
                            );
                        }
                        if (copy.payDate !== null) {
                            copy.payDate = DateUtils.convertLocalDateToServer(
                                copy.payDate
                            );
                        }
                        return angular.toJson(copy);
                    },
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.invoiceDate) {
                                data.invoiceDate = DateUtils.convertLocalDateFromServer(
                                    data.invoiceDate
                                );
                            }
                            if (data.payDate) {
                                data.payDate = DateUtils.convertLocalDateFromServer(
                                    data.payDate
                                );
                            }
                        }
                        return data;
                    },
                },
            }
        );
    }
})();

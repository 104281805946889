(function() {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('GFileStep', GFileStep);

    GFileStep.$inject = ['$resource', 'DateUtils'];

    function GFileStep ($resource, DateUtils) {
        var resourceUrl =  'api/g-file-steps/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.date){
                          data.date = DateUtils.convertLocalDateFromServer(data.date);
                        }
                        if (data.date2){
                          data.date2 = DateUtils.convertLocalDateFromServer(data.date2);
                        }
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    if (copy.date !== null) {
                        copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    }
                    if (copy.date2 !== null) {
                        copy.date2 = DateUtils.convertLocalDateToServer(copy.date2);
                    }
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.date){
                          data.date = DateUtils.convertLocalDateFromServer(data.date);
                        }
                        if (data.date2){
                          data.date2 = DateUtils.convertLocalDateFromServer(data.date2);
                        }
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                  var copy = angular.copy(data);
                  if (copy.date !== null) {
                      copy.date = DateUtils.convertLocalDateToServer(copy.date);
                  }
                  if (copy.date2 !== null) {
                      copy.date2 = DateUtils.convertLocalDateToServer(copy.date2);
                  }
                  return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.date){
                          data.date = DateUtils.convertLocalDateFromServer(data.date);
                        }
                        if (data.date2){
                          data.date2 = DateUtils.convertLocalDateFromServer(data.date2);
                        }
                    }
                    return data;
                }
            }
        });
    }
})();

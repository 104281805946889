(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('file-type', {
                parent: 'entity',
                url: '/file-type?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'FileTypes'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/file-type/file-types.html',
                        controller: 'FileTypeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('file-type.edit', {
                parent: 'file-type',
                url: '/file-type/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'FileType'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/file-type/file-type-detail.html',
                        controller: 'FileTypeDetailController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Edición',
                    parent: 'file-type'
                },
                resolve: {
                    entity: ['$stateParams', 'FileType', function ($stateParams, FileType) {
                        return FileType.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        return {
                            name: $state.current.name || 'file-type',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('file-type.new', {
                parent: 'file-type',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'FileType'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/file-type/file-type-detail.html',
                        controller: 'FileTypeDetailController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Nuevo',
                    parent: 'file-type'
                },
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            id: null
                        };
                    },
                    previousState: ["$state", function ($state) {
                        return {
                            name: $state.current.name || 'file-type',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            });
    }

})();

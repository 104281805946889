(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('StepController', StepController);

    StepController.$inject = ["$scope", "Step", "StepNode", "jurisdictions", "$mdSidenav"];

    function StepController($scope, Step, StepNode, jurisdictions, $mdSidenav) {
        var vm = this;
        vm.changeJurisdiction = changeJurisdiction;
        vm.openNewStepForm = openNewStepForm;
        vm.editStep = edit_step;
        vm.toggleSidenav = buildToggler('left');

        if (angular.isUndefined(vm.searchItem)) {
            vm.searchItem = {};
            vm.searchItem.jurisdiction = "CIVIL";
            changeJurisdiction();
        }

        vm.jurisdictions = jurisdictions;
        vm.nodes = [];

        vm.newstepform = false;
        vm.editstepform = false;
        vm.updatetree = false;

        $scope.$watch('vm.updatetree', function() {
            if (vm.updatetree) {
                changeJurisdiction();
            }
            vm.updatetree = false;
        });

        function changeJurisdiction() {
            var query = {
                jurisdiction: vm.searchItem.jurisdiction
            };

            StepNode.query(query).$promise
                .then(function(data) {
                    vm.nodes = data;
                });
        }

        function openNewStepForm(stepParent) {
            if (stepParent) {
                Step.get({
                    id: stepParent
                }, onNewStepSuccess, onNewStepError);
            }
            else {
                vm.newstepform = true;
                vm.stepParent = null;
                vm.editstepform = false;
                vm.newdocumentform = false;
                vm.editdocumentform = false;
            }
        }

        function onNewStepSuccess(data) {
            vm.stepParent = data;
            vm.updatetree = true;
            vm.newstepform = true;
            vm.editstepform = false;
            vm.newdocumentform = false;
            vm.editdocumentform = false;
        }
        function onNewStepError() {
            //TODO
        }

        function edit_step(step_id, isdeleted) {
            vm.isdeletedstep = isdeleted;
            vm.step = {};
            Step.get({
                id: step_id
            }, onEditStepSuccess, onEditStepError);
        }

        function onEditStepSuccess(data) {
            vm.step = data;
            vm.editstepform = true;
            $scope.isDeleting = false;

            vm.newstepform = false;
            vm.newdocumentform = false;
            vm.editdocumentform = false;
        }

        function onEditStepError() {
            //TODO
        }
        
        function buildToggler(navID) {
          return function() {
            // Component lookup should always be available since we are not using `ng-if`
            $mdSidenav(navID).toggle().then(function () {
            });
          };
        }
    }
})();

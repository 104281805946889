(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('FileTypeNewController', FileTypeNewController);

    FileTypeNewController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'FileType'];

    function FileTypeNewController ($timeout, $scope, $state, $stateParams,  FileType) {
        var vm = this;

        vm.fileType = {};
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go("file-type");
        }

        function save () {
            vm.isSaving = true;
            if (vm.fileType.id !== null) {
                FileType.update(vm.fileType, onSaveSuccess, onSaveError);
            } else {
                FileType.save(vm.fileType, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('expedientesUriarteApp:fileTypeUpdate', result);
            vm.isSaving = false;
            $state.go('file-type', null, { });
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();

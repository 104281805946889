(function() {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('Lawyers', Lawyers);

    Lawyers.$inject = ['$resource'];

    function Lawyers ($resource) {
        var resourceUrl =  'api/lawyers';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .component('editAdviceDocument', {
            bindings: {
                document: '=',
                action: '='
            },
            templateUrl: 'app/entities/advices/documents/edit-advice-document.component.html',
            controller: ['$scope', 'AdviceDocument', 'Upload', '$http', 'DateUtils', 'toastr',
                function ($scope, AdviceDocument,  Upload, $http, DateUtils, toastr) {
                    var $ctrl = this;

                    $ctrl.$onInit = initialization;
                    $ctrl.save = save;
                    $ctrl.close = close;
                    $ctrl.uploadfile = uploadfile;
                    $ctrl.downloadfile = downloadfile;
                    $ctrl.remove_document = remove_document;

                    function initialization() {

                    }

                    function save() {
                       $ctrl.isSaving = true;
                       var document = Object.assign({}, $ctrl.document);
                       AdviceDocument.update(document, onSaveSuccess, onSaveError);
                    }

                    function close() {
                      $ctrl.document = {};
                      $ctrl.action = 'query';
                    }

                    function uploadfile(file, errFiles) {
                      if (file) {
                        $ctrl.file = file;
                        $ctrl.document.filename = file.name;
                      }
                    }

                    function upload() {
                      var data = {
                            file: $ctrl.file,
                            name: $ctrl.file.name,
                            adviceId: $ctrl.document.advice,
                            documentID: $ctrl.document.id
                            };
                      Upload.upload({
                                url: "api/advices-documents/upload",
                                data: data,
                                method: 'POST'
                            }).then(
                                function() {
                                    $ctrl.isSaving = false;
                                    toastr.success('Documento subido con éxito');
                                    close();
                                },
                                function(response) {
                                    $ctrl.isSaving = false;
                                    toastr.error('Error al subir el documento');
                                },
                                function() {});
                    }

                    function onUploadSuccess(result) {
                      $ctrl.document = result;
                    }

                    function onUploadError() {
                        $ctrl.isSaving = false;
                    }

                    function onSaveSuccess(result) {
                        if ($ctrl.file) {
                          upload();
                        }
                        else {
                          $ctrl.isSaving = false;
                          toastr.success('Documento actualizado con éxito');
                          close();
                        }
                    }

                    function onSaveError() {
                        $ctrl.isSaving = false;
                        toastr.error('Error al actualizar el documento');
                    }

                    function remove_document() {
                        AdviceDocument.delete({
                                id: $ctrl.document.id
                            },
                            function() {
                                toastr.success('Documento eliminado con éxito');
                                close();
                            },
                            function () {
                                toastr.error('Error al eliminar el documento');
                            });
                    }

                    function downloadfile() {
                        $http({
                            method: 'GET',
                            url: 'api/advice-documents/download/' + $ctrl.document.id,
                            responseType: 'arraybuffer',
                            cache: false
                        }).success(function(data) {
                            var a = document.createElement('a');
                            var blob = new Blob([data], {
                                'type': "application/octet-stream"
                            });
                            a.href = URL.createObjectURL(blob);
                            a.download = data.filename;
                            a.click();
                        }).error(function(data, status, headers, config) {

                        });
                    }

                }
            ]
        });
})();

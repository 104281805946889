(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .component('editStep', {
            bindings: {
                step: '=',
                editstepform: '=',
                updatetree: '=',
                isdeletedstep: '<'
            },
            templateUrl: 'app/entities/step/edit-step-component.html',
            controller: ['$scope', 'Step', 'StepStatus', 'toastr',
                function ($scope, Step, StepStatus, toastr) {
                    var $ctrl = this;

                    $ctrl.$onInit = initialization;
                    $ctrl.save = save;
                    $ctrl.close = close;
                    $ctrl.remove_step = remove_step;
                    $ctrl.save_status = save_status;
                    $ctrl.delete_status = delete_status;


                    function initialization() {}

                    function findStatus() {
                      StepStatus.query({
                          stepId: $ctrl.step.id
                      }).$promise
                          .then(function(data) {
                              $ctrl.stepStatus = data;
                          });
                    }

                    $scope.$watch('$ctrl.step', function() {
                        if ($ctrl.step && $ctrl.step.status){
                            findStatus();
                        }
                    });

                    function save() {
                        $ctrl.isSaving = true;
                        var step = Object.assign({}, $ctrl.step);
                        Step.update(step, onSaveSuccess, onSaveError);
                    }

                    function onSaveSuccess(result) {
                        toastr.success('Actualizada con éxito!', 'Fase ' + result.name);

                        $ctrl.updatetree = true;
//                        $ctrl.editstepform = false;
                        $ctrl.isSaving = false;
                    }

                    function onSaveError() {
                        toastr.error('Error al actualizar la fase');
                        $ctrl.isSaving = false;
                    }

                    function close() {
                        $ctrl.editstepform = false;
                    }

                    function save_status() {
                        $ctrl.isSaving = true;

                        var step_status = {
                            step: $ctrl.step,
                            description: $ctrl.status
                        };

                        StepStatus.update(step_status, onSaveStepSuccess, onSaveStepError);
                    }

                    function onSaveStepSuccess (result) {
                        toastr.success('Estado añadido con éxito!', 'Estado ' + result.description);
                          $ctrl.status = undefined;
                          $ctrl.isSaving = false;
                          findStatus();
                    }

                    function onSaveStepError () {
                        toastr.error('Error al añadir estado');
                        $ctrl.isSaving = false;
                    }
                    function delete_status(idStatus) {
                        StepStatus.delete({
                                id: idStatus
                            },
                            function() {
                                toastr.success('Estado eliminado con éxito');
                                findStatus();
                            },
                            function () {
                                toastr.error('No puede eliminar el estado');
                            }
                        );
                    }
                    function remove_step() {
                        Step.delete({
                                id: $ctrl.step.id
                            },
                            function() {
                                toastr.success('Fase eliminada con éxito');
                                $ctrl.updatetree = true;
                                $ctrl.editstepform = false;
                                $ctrl.isdeletedstep = false;
                            },
                            function () {
                                toastr.error('No puede eliminarse una fase con subfases');
                            }
                        );
                    }

                }
            ]
        });
})();

(function() {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('AdviceStatus', AdviceStatus);

    AdviceStatus.$inject = ['$resource'];

    function AdviceStatus ($resource) {
        var resourceUrl =  'api/advice-status';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

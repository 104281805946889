(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller("LebaniegoYearResultDonorNewController", LebaniegoYearResultDonorNewController);

        LebaniegoYearResultDonorNewController.$inject = [
        "$rootScope",
        "$timeout",
        "$scope",
        "$http",
        "$state",
        "$mdSidenav",
        "$q",
        "entity",
        "LebaniegoYearResultDonor",
        "previousState",
        "toastr",
        "DateUtils",
    ]

    function LebaniegoYearResultDonorNewController(
        $rootScope,
        $timeout,
        $scope,
        $http,
        $state,
        $mdSidenav,
        $q,
        entity,
        LebaniegoYearResultDonor,
        previousState,
        toastr,
        DateUtils
    ) {
        var vm = this;

        vm.lebaniegoYearResultDonor = entity;
        vm.clear = clear;
        vm.save = save;
        vm.toggleSidenav = buildToggler("left");

        // Retrieve the value(s)
        vm.searchItem = JSON.parse(
            localStorage.getItem("searchLebaniegoYearResultDonorParameters")
        );
        
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            var lebaniegoYearResultDonor = Object.assign({}, vm.lebaniegoYearResultDonor);
            lebaniegoYearResultDonor.id = null;
            lebaniegoYearResultDonor.year = vm.lebaniegoYearResultDonor.year;
            lebaniegoYearResultDonor.sector = 'DONANTE';
            vm.lebaniegoYearResultDonor.gFile = {
                id: vm.lebaniegoYearResultDonor.gFile
            };
            lebaniegoYearResultDonor.gFile = vm.lebaniegoYearResultDonor.gFile;
            LebaniegoYearResultDonor.save(lebaniegoYearResultDonor, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success("Creada con éxito!");
            vm.isSaving = false;
            $state.go("lebaniego-result-donor", {});
        }

        function onSaveError() {
            toastr.error("Error al crear el donante");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                $mdSidenav(navID).toggle().then(function () {});
            }
        }

        $scope.query = function(searchText) {
            return $http
            .get('/api/ly-result/g-file/search?searchText=' + searchText + '&jurisdiction=AÑO_LEBANIEGO_DONANTES')
            .then(function(data) {
                var result = data.data, deferred;
                deferred = $q.defer();
                deferred.resolve(result);
                return result;
            });
        };

        $scope.selectedItemChange = function (item) {
            if (item) {
                vm.lebaniegoYearResultDonor.businessName = item.sectorBusinessName;
                vm.lebaniegoYearResultDonor.gFile = item.gFileId;
            }
        }
    }
})();
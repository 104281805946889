(function () {
    "use strict";
    angular
        .module("expedientesUriarteApp")
        .factory(
            "ConsortiumExpedienteExpropiacion",
            ConsortiumExpedienteExpropiacion
        );

    ConsortiumExpedienteExpropiacion.$inject = ["$resource", "DateUtils"];

    function ConsortiumExpedienteExpropiacion($resource, DateUtils) {
        var resourceUrl = "api/consortium-expediente-expropiacion/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.date) {
                                data.date = DateUtils.convertLocalDateFromServer(
                                    data.date
                                );
                            }
                            if (data.fairPriceDate) {
                                data.fairPriceDate = DateUtils.convertLocalDateFromServer(
                                    data.fairPriceDate
                                );
                            }
                        }
                        return data;
                    },
                },
                update: {
                    method: "PUT",
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        if (copy.date !== null) {
                            copy.date = DateUtils.convertLocalDateToServer(
                                copy.date
                            );
                        }
                        if (copy.fairPriceDate !== null) {
                            copy.fairPriceDate = DateUtils.convertLocalDateToServer(
                                copy.fairPriceDate
                            );
                        }
                        return angular.toJson(copy);
                    },
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.date) {
                                data.date = DateUtils.convertLocalDateFromServer(
                                    data.date
                                );
                            }
                            if (data.fairPriceDate) {
                                data.fairPriceDate = DateUtils.convertLocalDateFromServer(
                                    data.fairPriceDate
                                );
                            }
                        }
                        return data;
                    },
                },
                save: {
                    method: "POST",
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        if (copy.date !== null) {
                            copy.date = DateUtils.convertLocalDateToServer(
                                copy.date
                            );
                        }
                        if (copy.fairPriceDate !== null) {
                            copy.fairPriceDate = DateUtils.convertLocalDateToServer(
                                copy.fairPriceDate
                            );
                        }
                        return angular.toJson(copy);
                    },
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.date) {
                                data.date = DateUtils.convertLocalDateFromServer(
                                    data.date
                                );
                            }
                            if (data.fairPriceDate) {
                                data.fairPriceDate = DateUtils.convertLocalDateFromServer(
                                    data.fairPriceDate
                                );
                            }
                        }
                        return data;
                    },
                },
            }
        );
    }
})();

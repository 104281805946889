(function () {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('ConsortiumPersonalSeguro', ConsortiumPersonalSeguro);

    ConsortiumPersonalSeguro.$inject = ['$resource', 'DateUtils'];

    function ConsortiumPersonalSeguro($resource, DateUtils) {
        var resourceUrl = 'api/consortium-personal-seguros/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'update': {
                method: 'PUT'
            },
            'save': {
                method: 'POST'
            }
        });
    }
})();

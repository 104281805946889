(function () {
    "use strict";
    angular
        .module("expedientesUriarteApp")
        .factory(
            "LebaniegoYearAmount",
            LebaniegoYearAmount
        );

        LebaniegoYearAmount.$inject = ["$resource", "DateUtils"];

    function LebaniegoYearAmount($resource) {
        var resourceUrl = "api/ly-amount/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET" },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        return data;
                    },
                }
            }
        );
    }
})();

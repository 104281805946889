(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('FileTypeDetailController', FileTypeDetailController);

    FileTypeDetailController.$inject = ['previousState', 'entity', 'FileType', '$state', 'toastr'];

    function FileTypeDetailController(previousState, entity, FileType, $state, toastr) {
        var vm = this;

        vm.fileType = entity;
        vm.previousState = previousState.name;
        vm.deleteFileType = deleteFileType;
        vm.clear = clear;

        vm.save = save;

        function save () {
            vm.isSaving = true;
            if (vm.fileType.id !== null) {
                FileType.update(vm.fileType, onSaveSuccess, onSaveError);
            } else {
                FileType.save(vm.fileType, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            toastr.success('Actualizado con éxito!', 'Tipo de expediente ' + result.name);
            $state.go('file-type');
            vm.isSaving = false;
        }

        function onSaveError () {
            toastr.error('Error al actualizar el tipo de expediente');
            vm.isSaving = false;
        }

        function deleteFileType() {
            FileType.delete({id: vm.fileType.id}, onDeleteSuccess, onDeleteError);
        }

        function onDeleteSuccess() {
            toastr.success('Tipo de expediente eliminado con éxito');
            $state.go('file-type');
            vm.isSaving = false;
        }

        function onDeleteError() {
            toastr.error('Error al eliminar el tipo de expediente');
            vm.isSaving = false;
        }

        function clear() {
            $state.go('file-type');
        }

    }
})();

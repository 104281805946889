(function () {
    "use strict";
    angular
        .module("expedientesUriarteApp")
        .factory("ConsortiumExpedienteJuridicoInforme", ConsortiumExpedienteJuridicoInforme);

    ConsortiumExpedienteJuridicoInforme.$inject = ["$resource", "DateUtils"];

    function ConsortiumExpedienteJuridicoInforme($resource, DateUtils) {
        var resourceUrl = "api/consortium-expediente-juridicos-informes/:id";

        return $resource(
            resourceUrl, {}, {
                query: {
                    method: "GET",
                    isArray: true,
                },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.date) {
                                data.date = DateUtils.convertLocalDateFromServer(
                                    data.date
                                );
                            }
                        }
                        return data;
                    },
                },
                update: {
                    method: "PUT",
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        if (copy.date !== null) {
                            copy.date = DateUtils.convertLocalDateToServer(
                                copy.date
                            );
                        }
                        return angular.toJson(copy);
                    },
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.date) {
                                data.date = DateUtils.convertLocalDateFromServer(
                                    data.date
                                );
                            }
                        }
                        return data;
                    },
                },
                save: {
                    method: "POST",
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        if (copy.date !== null) {
                            copy.date = DateUtils.convertLocalDateToServer(
                                copy.date
                            );
                        }
                        return angular.toJson(copy);
                    },
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.date) {
                                data.date = DateUtils.convertLocalDateFromServer(
                                    data.date
                                );
                            }
                        }
                        return data;
                    },
                },
            }
        );
    }
})();

(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller(
            "LebaniegoYearContactosSectorOsDetailController",
            LebaniegoYearContactosSectorOsDetailController
        );

    LebaniegoYearContactosSectorOsDetailController.$inject = [
        "$mdDialog",
        "entity",
        "Principal",
        "LebaniegoYearContactosSectorOs",
        "toastr",
        "$mdSidenav",
        "$state",
        "previousState",
        "$http",
        "Upload",
    ]

    function LebaniegoYearContactosSectorOsDetailController(
        $mdDialog,
        entity,
        Principal,
        LebaniegoYearContactosSectorOs,
        toastr,
        $mdSidenav,
        $state,
        previousState,
        $http,
        Upload
    ) {
        var vm = this;

        vm.lebaniegoYearContactosSectorOs = entity;
        vm.clear = clear;

        vm.toggleSidenav = buildToggler("left");
        vm.save = save;

        vm.updatetree = false;
        vm.nodes = [];

        vm.actualSector = vm.lebaniegoYearContactosSectorOs.businessName;

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        Principal.hasAuthority("ROLE_ADMIN").then(function (result) {
            vm.isAdmin = true;
        })
        Principal.hasAuthority("ROL_JUBILAR").then(function (result) {
            vm.isAdmin = true;
        })

        function onSuccess(data, headers) {
            vm.documents = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function save() {
            vm.isSaving = true;
            var lebaniegoYearContactosSectorOs = Object.assign({}, vm.lebaniegoYearContactosSectorOs);

            LebaniegoYearContactosSectorOs.update(lebaniegoYearContactosSectorOs, onSaveSuccess, onError);
        }

        function onSaveSuccess(result) {
            vm.actualSector = vm.lebaniegoYearContactosSectorOs.businessName;
            toastr.success("Guardada con éxito!");
            vm.isSaving = false;
        }

        function onSaveError() {
            toastr.error("Error al guardar");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                // Component lookup should always be available since we are not using `ng-if`
                $mdSidenav(navID)
                    .toggle()
                    .then(function () {});
            };
        }
    }
})();
(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .component('notes', {
            bindings: {
                entitytype: '<',
                entityid: '<'
            },
            templateUrl: 'app/entities/observation/observations-component.html',
            controller: ['$timeout', '$rootScope', '$scope', 'Observation', '$q', 'toastr',
                function($timeout, $rootScope, $scope, Observation, $q, toastr) {
                    var $ctrl = this;

                    $ctrl.saved = false;

                    $ctrl.onEdit = false;
                    $ctrl.editObservation = {};
                    $ctrl.editCancel = editCancel;
                    $ctrl.editUpdate = editUpdate;
                    $ctrl.editNote = editNote;
                    $ctrl.editDelete = editDelete;

                    $ctrl.onNew = false;
                    $ctrl.newCancel = newCancel;
                    $ctrl.newSave = newSave;
                    $ctrl.newObservation = {};
                    $ctrl.notes = {};
                    $ctrl.$onInit = onInit;

                    $ctrl.$onChanges = function(changes) {
                        retrieveNotes();
                    };

                    function onInit() {
                        retrieveNotes();
                        $ctrl.onEdit = false;
                    }

                    function newCancel() {
                        $ctrl.onNew = false;
                        $ctrl.newObservation = {};
                    }

                    function newSave() {
                        Observation.save($ctrl.newObservation, onNewSaveSucess, onNewSaveError);
                    }

                    function onNewSaveSucess(data) {
                        $ctrl.onNew = false;
                        toastr.success('Nota creada con éxito!');
                        $ctrl.newObservation = {};
                        retrieveNotes();
                    }

                    function onNewSaveError() {
                        toastr.error('Error al crear la nota!');
                    }

                    function editNote(observation_id) {
                        Observation.get({
                            id: observation_id
                        }, onEditObservationSuccess, onEditObservationError);
                    }

                    function onEditObservationSuccess(data) {
                        $ctrl.onEdit = true;
                        $ctrl.editObservation = data;
                    }

                    function onEditObservationError() {
                        //TODO
                    }

                    function editDelete() {
                        Observation.delete($ctrl.editObservation, onEditDeleteSucess, onEditDeleteError);
                    }

                    function onEditDeleteSucess(data) {
                        $ctrl.onEdit = false;
                        toastr.success('Nota eliminada con éxito!');
                        $ctrl.editObservation = {};
                        retrieveNotes();
                    }

                    function onEditDeleteError() {
                        toastr.error('Error al eliminar la nota!');
                    }

                    function editCancel() {
                        $ctrl.onEdit = false;
                    }

                    function editUpdate() {
                        $ctrl.onEdit = false;
                        Observation.update($ctrl.editObservation, onEditUpdateSucess, onEditUpdateError);
                    }

                    function onEditUpdateSucess(data) {
                        $ctrl.onEdit = false;
                        toastr.success('Nota actualizada con éxito!');
                        $ctrl.editObservation = {};
                        retrieveNotes();
                    }

                    function onEditUpdateError() {
                        toastr.error('Error al actualizar la nota!');
                    }


                    function retrieveNotes() {
                        $q.all([
                                Observation.query({
                                  entityType: $ctrl.entitytype,
                                  entityId: $ctrl.entityid,
                                  type: 'NOTE'
                                }).$promise
                            ])
                            .then(function(data) {
                                $ctrl.notes = buildGridModel(data[0]);
                                $rootScope.hasNotes = $ctrl.notes.length;
                            });
                    }

                    function buildGridModel(notes) {
                        var it, results = [];

                        for (var j = 0; j < notes.length; j++) {
                            it = angular.extend({}, notes[j]);
                            it.span = {
                                row: 1,
                                col: 1
                            };

                            switch (j + 1) {
                                case 1:
                                    it.background = "red";
                                    it.span.row = it.span.col = 2;
                                    break;

                                case 2:
                                    it.background = "green";
                                    break;
                                case 3:
                                    it.background = "darkBlue";
                                    break;
                                case 4:
                                    it.background = "blue";
                                    it.span.col = 2;
                                    break;

                                case 5:
                                    it.background = "yellow";
                                    it.span.row = it.span.col = 2;
                                    break;

                                case 6:
                                    it.background = "pink";
                                    break;
                                case 7:
                                    it.background = "darkBlue";
                                    break;
                                case 8:
                                    it.background = "purple";
                                    break;
                                case 9:
                                    it.background = "deepBlue";
                                    break;
                                case 10:
                                    it.background = "lightPurple";
                                    break;
                                case 11:
                                    it.background = "yellow";
                                    break;
                                default:
                                    it.background = "yellow";
                                    break;
                            }

                            results.push(it);
                        }
                        return results;
                    }

                }
            ]
        });
})();

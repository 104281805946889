(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller("LebaniegoYearContactosSectorBacNewController", LebaniegoYearContactosSectorBacNewController);

    LebaniegoYearContactosSectorBacNewController.$inject = [
        "$rootScope",
        "$timeout",
        "$scope",
        "entity",
        "LebaniegoYearContactosSectorBac",
        "$state",
        "previousState",
        "toastr",
        "DateUtils",
        "$mdSidenav",
    ]

    function LebaniegoYearContactosSectorBacNewController(
        $rootScope,
        $timeout,
        $scope,
        entity,
        LebaniegoYearContactosSectorBac,
        $state,
        previousState,
        toastr,
        DateUtils,
        $mdSidenav
    ) {
        var vm = this;

        vm.lebaniegoYearContactosSectorBac = entity;
        vm.clear = clear;
        vm.save = save;
        vm.toggleSidenav = buildToggler("left");

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            var lebaniegoYearContactosSectorBac = Object.assign({}, vm.lebaniegoYearContactosSectorBac);
            lebaniegoYearContactosSectorBac.id = null;
            lebaniegoYearContactosSectorBac.sector = "Bebidas y alimentación de Cantabria";
            LebaniegoYearContactosSectorBac.save(lebaniegoYearContactosSectorBac, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success("Creada con éxito!");
            vm.isSaving = false;
            $state.go("lebaniego-contacts-sector-bac", {});
        }

        function onSaveError() {
            toastr.error("Error al crear el acta");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                $mdSidenav(navID).toggle().then(function () {});
            }
        }
    }
})();
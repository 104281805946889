(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('advices', {
                parent: 'entity',
                url: '/advices',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CLIENT'],
                    pageTitle: 'Advices'
                },
                views: {
                  'content@': {
                    templateUrl: 'app/entities/advices/advice/advices.html',
                    controller: 'AdvicesController',
                    controllerAs: 'vm'
                  }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    status: ['$stateParams', 'AdviceStatus', function($stateParams, AdviceStatus) {
                        return AdviceStatus.query().$promise;
                    }]
                }
            })
            .state('advices.edit', {
                parent: 'advices',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CLIENT'],
                    pageTitle: 'Advice'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/advices/advice/advice-detail.html',
                        controller: 'AdviceDetailController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                      label: 'Administrar',
                      parent: 'advices'
                },
                resolve: {
                    entity: ['$stateParams', 'Advice', function($stateParams, Advice) {
                        return Advice.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    advicequerytypes: ['$stateParams', 'AdviceQueryType', function($stateParams, AdviceQueryType) {
                        return AdviceQueryType.query().$promise;
                    }],
                    advicestatus: ['$stateParams', 'AdviceStatus', function($stateParams, AdviceStatus) {
                        return AdviceStatus.query().$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'advices',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('advices.new', {
                parent: 'advices',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                  'content@': {
                    templateUrl: 'app/entities/advices/advice/advice-new.html',
                    controller: 'AdviceNewController',
                    controllerAs: 'vm'
                  }
                },
                ncyBreadcrumb: {
                      label: 'Nuevo',
                      parent: 'advices'
                },
                resolve: {
                    entity: function() {
                        return {
                            id: null
                        };
                    },
                    advicequerytypes: ['$stateParams', 'AdviceQueryType', function($stateParams, AdviceQueryType) {
                        return AdviceQueryType.query().$promise;
                    }],
                    advicestatus: ['$stateParams', 'AdviceStatus', function($stateParams, AdviceStatus) {
                        return AdviceStatus.query().$promise;
                    }],
                    previousState: ["$state", function ($state) {
                          var currentStateData = {
                              name: $state.current.name || 'advices',
                              params: $state.params,
                              url: $state.href($state.current.name, $state.params)
                          };
                          return currentStateData;
                    }]
                }
            });
    }

})();

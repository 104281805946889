(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('ConsortiumTributosDevolucionController', ConsortiumTributosDevolucionController);

        ConsortiumTributosDevolucionController.$inject = ['DateUtils', '$state', 'ConsortiumTributosDevolucion', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$mdSidenav', 'Principal'];

    function ConsortiumTributosDevolucionController (DateUtils, $state, ConsortiumTributosDevolucion, ParseLinks, AlertService, paginationConstants, pagingParams, $mdSidenav, Principal) {
        var vm = this;
        vm.searchExp = searchExp;
        vm.deleteSearchItem = deleteSearchItem;

        vm.toggleSidenav = buildToggler('left');

        // Retrieve the value(s)
        vm.searchItem = JSON.parse(localStorage.getItem('searchConsortiumTributosDevolucionParameters'));

        if (vm.searchItem === null) {
            vm.searchItem = {};
            vm.searchItem.year = new Date().getFullYear();
        }

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        Principal.hasAuthority("ROLE_CONSORTIUM").then(function(result) {
            if (result) vm.searchItem.consortium = "";
            searchExp();
        });

        function searchExp() {
            // Store the value(s)
            localStorage.setItem('searchConsortiumTributosDevolucionParameters', JSON.stringify(vm.searchItem));
            ConsortiumTributosDevolucion.query({
                year: vm.searchItem.year,
                consortium: vm.searchItem.consortium,
                text: vm.searchItem.text,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.consortiumTributosDevolucionItems = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function deleteSearchItem() {
          vm.searchItem = {};
          vm.searchItem.year = new Date().getFullYear();

          localStorage.setItem('searchConsortiumTributosDevolucionParameters', JSON.stringify(vm.searchItem));
          vm.page = 1;
          vm.transition();
          searchExp();
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function buildToggler(navID) {
          return function() {
            // Component lookup should always be available since we are not using `ng-if`
            $mdSidenav(navID).toggle().then(function () {
          });
        };
      }
    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .component('tasks', {
            bindings: {
                entitytype: '<',
                entityid: '<'
            },
            templateUrl: 'app/entities/task/tasks-component.html',
            controller: ['$timeout', '$rootScope', '$scope', 'Task', '$q', 'toastr',
                function($timeout, $rootScope, $scope, Task, $q, toastr) {
                    var $ctrl = this;
                    $ctrl.saved = false;

                    $ctrl.onEdit = false;
                    $ctrl.editTask = {};
                    $ctrl.editCancel = editCancel;
                    $ctrl.editUpdate = editUpdate;
                    $ctrl.modifyTask = modifyTask;
                    $ctrl.editDelete = editDelete;

                    $ctrl.onNew = false;
                    $ctrl.newCancel = newCancel;
                    $ctrl.newSave = newSave;
                    $ctrl.newTask = {};
                    $ctrl.tasks = {};
                    $ctrl.$onInit = onInit;

                    $ctrl.$onChanges = function(changes) {
                        retrieveTasks();
                    };

                    function onInit() {
                        retrieveTasks();
                        $ctrl.onEdit = false;
                    }

                    function newCancel() {
                        $ctrl.onNew = false;
                        $ctrl.newTask = {};
                    }

                    function newSave() {
                        Task.save($ctrl.newTask, onNewSaveSucess, onNewSaveError);
                    }

                    function onNewSaveSucess(data) {
                        $ctrl.onNew = false;
                        toastr.success('Tarea creada con éxito!');
                        $ctrl.newTask = {};
                        retrieveTasks();
                    }

                    function onNewSaveError() {
                        toastr.error('Error al crear la tarea!');
                    }

                    function modifyTask(task) {
                        if(!task.done){
                          Task.get({
                              id: task.id
                          }, onEditTaskSuccess, onEditTaskError);
                        }
                    }

                    function onEditTaskSuccess(data) {
                        $ctrl.onEdit = true;
                        $ctrl.editTask = data;
                        if ($ctrl.editTask.reminderDate !== null) {
                            $ctrl.editTask.reminderDate = new Date($ctrl.editTask.reminderDate);
                        }
                        $ctrl.editTask.registerDate = new Date($ctrl.editTask.registerDate);
                    }

                    function onEditTaskError() {
                    }

                    function editDelete() {
                        Task.delete($ctrl.editTask, onEditDeleteSucess, onEditDeleteError);
                    }

                    function onEditDeleteSucess(data) {
                        $ctrl.onEdit = false;
                        toastr.success('Tarea eliminada con éxito!');
                        $ctrl.editTask = {};
                        retrieveTasks();
                    }

                    function onEditDeleteError() {
                        toastr.error('Error al eliminar la tarea!');
                    }

                    function editCancel() {
                        $ctrl.onEdit = false;
                    }

                    function editUpdate() {
                        $ctrl.onEdit = false;
                        Task.update($ctrl.editTask, onEditUpdateSucess, onEditUpdateError);
                    }

                    function onEditUpdateSucess(data) {
                        $ctrl.onEdit = false;
                        toastr.success('Tarea actualizada con éxito!');
                        $ctrl.editTask = {};
                        retrieveTasks();
                    }

                    function onEditUpdateError() {
                        toastr.error('Error al actualizar la tarea!');
                    }


                    function retrieveTasks() {
                        $q.all([
                                Task.query({
                                    entityType: $ctrl.entitytype,
                                    entityId: $ctrl.entityid,
                                    type: 'TASK'
                                }).$promise
                            ])
                            .then(function(data) {
                                $ctrl.tasks = buildGridModel(data[0]);
                            });
                    }

                    function buildGridModel(tasks) {
                        var it, results = [];

                        for (var j = 0; j < tasks.length; j++) {

                            it = angular.extend({}, tasks[j]);
                            it.span = {
                                row: 1,
                                col: 1
                            };

                            switch (j + 1) {
                                case 1:
                                    it.background = "red";
                                    it.span.row = it.span.col = 2;
                                    break;

                                case 2:
                                    it.background = "green";
                                    break;
                                case 3:
                                    it.background = "darkBlue";
                                    break;
                                case 4:
                                    it.background = "blue";
                                    it.span.col = 2;
                                    break;

                                case 5:
                                    it.background = "yellow";
                                    it.span.row = it.span.col = 2;
                                    break;

                                case 6:
                                    it.background = "pink";
                                    break;
                                case 7:
                                    it.background = "darkBlue";
                                    break;
                                case 8:
                                    it.background = "purple";
                                    break;
                                case 9:
                                    it.background = "deepBlue";
                                    break;
                                case 10:
                                    it.background = "lightPurple";
                                    break;
                                default:
                                    it.background = "white";
                                    break;
                            }

                            if(tasks[j].done){
                              it.background = "done";
                            }

                            results.push(it);
                        }
                        return results;
                    }

                }
            ]
        });
})();

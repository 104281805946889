(function () {
    "use strict";
    angular
        .module("expedientesUriarteApp")
        .factory("LebaniegoYearDonor", LebaniegoYearDonor);

    LebaniegoYearDonor.$inject = ["$resource", "DateUtils"];

    function LebaniegoYearDonor($resource, DateUtils) {
        var resourceUrl = "api/g-donor-files/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.registerDate !== null) {
                                data.registerDate =
                                    DateUtils.convertLocalDateFromServer(
                                        data.registerDate
                                    );
                            }
                            if (data.notificationDate !== null) {
                                data.notificationDate =
                                    DateUtils.convertLocalDateFromServer(
                                        data.notificationDate
                                    );
                            }
                            if (data.presentationDate !== null) {
                                data.presentationDate =
                                    DateUtils.convertLocalDateFromServer(
                                        data.presentationDate
                                    );
                            }
                            if (data.sectorDate !== null) {
                                data.sectorDate =
                                    DateUtils.convertLocalDateFromServer(
                                        data.sectorDate
                                    );
                            }
                        }
                        return data;
                    },
                },
                update: {
                    method: "PUT",
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        if (data.registerDate !== null) {
                            data.registerDate =
                                DateUtils.convertLocalDateFromServer(
                                    data.registerDate
                                );
                        }
                        if (data.notificationDate !== null) {
                            data.notificationDate =
                                DateUtils.convertLocalDateFromServer(
                                    data.notificationDate
                                );
                        }
                        if (data.presentationDate !== null) {
                            data.presentationDate =
                                DateUtils.convertLocalDateFromServer(
                                    data.presentationDate
                                );
                        }
                        if (copy.sectorDate !== null) {
                            copy.sectorDate =
                                DateUtils.convertLocalDateToServer(
                                    copy.sectorDate
                                );
                        }
                        return angular.toJson(copy);
                    },
                    transformResponse: function (data) {
                        if (data) {
                            var data = angular.fromJson(data);
                            if (data.registerDate !== null) {
                                data.registerDate =
                                    DateUtils.convertLocalDateFromServer(
                                        data.registerDate
                                    );
                            }
                            if (data.notificationDate !== null) {
                                data.notificationDate =
                                    DateUtils.convertLocalDateFromServer(
                                        data.notificationDate
                                    );
                            }
                            if (data.presentationDate !== null) {
                                data.presentationDate =
                                    DateUtils.convertLocalDateFromServer(
                                        data.presentationDate
                                    );
                            }
                            if (data.sectorDate !== null) {
                                data.sectorDate =
                                    DateUtils.convertLocalDateFromServer(
                                        data.sectorDate
                                    );
                            }
                        }
                        return data;
                    },
                },
                save: {
                    method: "POST",
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        if (data.registerDate !== null) {
                            data.registerDate =
                                DateUtils.convertLocalDateFromServer(
                                    data.registerDate
                                );
                        }
                        if (data.notificationDate !== null) {
                            data.notificationDate =
                                DateUtils.convertLocalDateFromServer(
                                    data.notificationDate
                                );
                        }
                        if (data.presentationDate !== null) {
                            data.presentationDate =
                                DateUtils.convertLocalDateFromServer(
                                    data.presentationDate
                                );
                        }
                        if (copy.sectorDate !== null) {
                            copy.sectorDate =
                                DateUtils.convertLocalDateToServer(
                                    copy.sectorDate
                                );
                        }
                        return angular.toJson(copy);
                    },
                    transformResponse: function (data) {
                        if (data) {
                            var data = angular.fromJson(data);
                            if (data.registerDate !== null) {
                                data.registerDate =
                                    DateUtils.convertLocalDateFromServer(
                                        data.registerDate
                                    );
                            }
                            if (data.notificationDate !== null) {
                                data.notificationDate =
                                    DateUtils.convertLocalDateFromServer(
                                        data.notificationDate
                                    );
                            }
                            if (data.presentationDate !== null) {
                                data.presentationDate =
                                    DateUtils.convertLocalDateFromServer(
                                        data.presentationDate
                                    );
                            }
                            if (data.sectorDate !== null) {
                                data.sectorDate =
                                    DateUtils.convertLocalDateFromServer(
                                        data.sectorDate
                                    );
                            }
                        }
                        return data;
                    },
                },
            }
        );
    }
})();

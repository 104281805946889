(function () {
    "use strict";

    angular.module("expedientesUriarteApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("lebaniego-amount", {
            parent: "entity",
            url: "/lebaniego-amount?page&sort&search",
            data: {
                authorities: ["ROLE_ADMIN", "ROLE_JUBILAR"],
                pageTitle: "LebaniegoYearAmount",
            },
            views: {
                "content@": {
                    templateUrl:
                        "app/entities/lebaniego/lebaniego-cuantias/lebaniego-cuantia.html",
                    controller: "LebaniegoYearAmountController",
                    controllerAs: "vm",
                },
            },
            params: {
                page: {
                    value: "1",
                    squash: true,
                },
                sort: {
                    value: "id,asc",
                    squash: true,
                },
                search: null,
            },
            resolve: {
                pagingParams: [
                    "$stateParams",
                    "PaginationUtil",
                    function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate(
                                $stateParams.sort
                            ),
                            ascending: PaginationUtil.parseAscending(
                                $stateParams.sort
                            ),
                            search: $stateParams.search,
                        };
                    },
                ],
            },
        });
    }
})();

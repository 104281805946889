(function () {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            ncyBreadcrumb: {
                label: 'Inicio'
            },
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CLIENT','ROLE_CONSORTIUM', 'ROLE_JUBILAR']
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                graphData: ['$stateParams', 'Graph', function ($stateParams, Graph) {
                    return Graph.get({
                        type: "data"
                    }).$promise;
                }],
                graphGFileTypeMonth: ['$stateParams', 'Graph', function ($stateParams, Graph) {
                    return Graph.get({
                        type: "gfile-type",
                        unit: "month"
                    }).$promise;
                }],
                graphGFileTypeYear: ['$stateParams', 'Graph', function ($stateParams, Graph) {
                    return Graph.get({
                        type: "gfile-type",
                        unit: "anual"
                    }).$promise;
                }],
                graphGFileDate: ['$stateParams', 'Graph', function ($stateParams, Graph) {
                    return Graph.get({
                        type: "gfile-date"
                    }).$promise;
                }],
                graphGFileStep: ['$stateParams', 'Graph', function ($stateParams, Graph) {
                    return Graph.get({
                        type: "gfile-step"
                    }).$promise;
                }],
                graphNotificationGroupDate: ['$stateParams', 'Graph', function ($stateParams, Graph) {
                    return Graph.get({
                        type: "notification-groupdate"
                    }).$promise;
                }]
            }
        });
    }
})();

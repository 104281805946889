(function() {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('ConsortiumTributosEell', ConsortiumTributosEell);

        ConsortiumTributosEell.$inject = ['$resource', 'DateUtils'];

    function ConsortiumTributosEell ($resource, DateUtils) {
        var resourceUrl =  'api/consortium-tributos-eell/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.paymentDate){
                          data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        }
                    }
                    return data;
                }
            },
            'update': { method:'PUT',
            transformRequest: function (data) {
                var copy = angular.copy(data);
                if (copy.paymentDate !== null) {
                    copy.paymentDate = DateUtils.convertLocalDateToServer(copy.paymentDate);
                }
                return angular.toJson(copy);
            },
            transformResponse: function (data) {
                if (data) {
                    data = angular.fromJson(data);
                    if (data.paymentDate){
                    data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                    }
                }
                return data;
            }  
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    if (copy.paymentDate !== null) {
                        copy.paymentDate = DateUtils.convertLocalDateToServer(copy.paymentDate);
                    }
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.paymentDate){
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        }
                    }
                    return data;
                }    
            }
        });
    }
})();

(function() {
    'use strict';

    angular
    .module('expedientesUriarteApp')
    .directive('confirmDelete',confirmDelete);

    //direcitves controller

     confirmDeleteCtrl.$inject = ['$scope'];

     function confirmDeleteCtrl($scope) {
        $scope.isDeleting = false;
        $scope.startDelete = function () {
           $scope.isDeleting = true;
         };
         $scope.cancel = function () {
           $scope.isDeleting = false;
         };
         $scope.confirm = function () {
           $scope.isDeleting = false;
           $scope.onConfirm();
         };
    }
/**
 * Directive to confirm record delete
 */
function confirmDelete() {
        return {
            replace: true,
            templateUrl: 'app/shared/utils/template/confirmDelete.html',
            scope: {
                onConfirm: '&'
            },
            controller: confirmDeleteCtrl
        };
    }
})();

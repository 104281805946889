(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('consortium-personal-nominas', {
                parent: 'entity',
                url: '/consortium-personal-nominas?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CONSORTIUM'],
                    pageTitle: 'ConsortiumPersonalNomina'
                },
                views: {
                  'content@': {
                    templateUrl: 'app/entities/consortium-personal/consortium-personal-nominas/consortium-personal-nominas.html',
                    controller: 'ConsortiumPersonalNominaController',
                    controllerAs: 'vm'
                  }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('consortium-personal-nominas.new', {
                parent: 'consortium-personal-nominas',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                  'content@': {
                    templateUrl: 'app/entities/consortium-personal/consortium-personal-nominas/consortium-personal-nominas-new.html',
                    controller: 'ConsortiumPersonalNominaNewController',
                    controllerAs: 'vm'
                  }
                },
                ncyBreadcrumb: {
                      label: 'Nuevo',
                      parent: 'consortium-personal-nominas'
                },
                resolve: {
                    entity: function() {
                        return {
                            id: null
                        };
                    },
                    previousState: ["$state", function ($state) {
                          var currentStateData = {
                              name: $state.current.name || 'consortium-personal-nominas',
                              params: $state.params,
                              url: $state.href($state.current.name, $state.params)
                          };
                          return currentStateData;
                    }]
                }
            }).state('consortium-personal-nominas.edit', {
                parent: 'consortium-personal-nominas',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CONSORTIUM'],
                    pageTitle: 'ConsortiumPersonalNomina'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/consortium-personal/consortium-personal-nominas/consortium-personal-nominas-detail.html',
                        controller: 'ConsortiumPersonalNominaDetailController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                      label: 'Lista',
                      parent: 'consortium-personal-nominas'
                },
                resolve: {
                    entity: ['$stateParams', 'ConsortiumPersonalNomina', function($stateParams, ConsortiumPersonalNomina) {
                        return ConsortiumPersonalNomina.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'consortium-personal-nominas',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            });
    }

})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('ConsortiumTributosAeatNewController', ConsortiumTributosAeatNewController);

        ConsortiumTributosAeatNewController.$inject = ['$rootScope', '$timeout', '$scope', 'entity', 'ConsortiumTributosAeat', '$state', 'previousState', 'toastr', 'DateUtils', '$mdSidenav', 'ConsortiumUsers'];

    function ConsortiumTributosAeatNewController($rootScope, $timeout, $scope, entity, ConsortiumTributosAeat, $state, previousState,toastr, DateUtils, $mdSidenav, ConsortiumUsers) {
        var vm = this;

        vm.consortiumTributosAeat = entity;
        vm.clear = clear;
        vm.save = save;
        vm.toggleSidenav = buildToggler('left');

        ConsortiumUsers.query({}, onSuccess, onError);
        
        function onSuccess(data, headers) {
            vm.consortiumUsers = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            var consortiumTributosAeat = Object.assign({}, vm.consortiumTributosAeat);
            consortiumTributosAeat.id = null;
            ConsortiumTributosAeat.save(consortiumTributosAeat, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success('Creado con éxito!');
            vm.isSaving = false;
            $state.go('consortium-tributos-aeat', {
            });
        }

        function onSaveError() {
            toastr.error('Error al crear el tributo');
            vm.isSaving = false;
        }
        
        function buildToggler(navID) {
            return function() {
              // Component lookup should always be available since we are not using `ng-if`
              $mdSidenav(navID).toggle().then(function () {
              });
              };
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('consortium-tributos-revision', {
                parent: 'entity',
                url: '/consortium-tributos-revision?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CONSORTIUM'],
                    pageTitle: 'ConsortiumTributosRevision'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/consortium-tributos/consortium-tributos-revision/consortium-tributos-revision.html',
                        controller: 'ConsortiumTributosRevisionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('consortium-tributos-revision.new', {
                parent: 'consortium-tributos-revision',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/consortium-tributos/consortium-tributos-revision/consortium-tributos-revision-new.html',
                        controller: 'ConsortiumTributosRevisionNewController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Nuevo',
                    parent: 'consortium-tributos-revision'
                },
                resolve: {
                    entity: function () {
                        return {
                            id: null
                        };
                    },
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'consortium-tributos-revision',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            }).state('consortium-tributos-revision.edit', {
                parent: 'consortium-tributos-revision',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CONSORTIUM'],
                    pageTitle: 'ConsortiumTributosRevision'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/consortium-tributos/consortium-tributos-revision/consortium-tributos-revision-detail.html',
                        controller: 'ConsortiumTributosRevisionDetailController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Lista',
                    parent: 'consortium-tributos-revision'
                },
                resolve: {
                    entity: ['$stateParams', 'ConsortiumTributosRevision', function ($stateParams, ConsortiumTributosRevision) {
                        return ConsortiumTributosRevision.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'consortium-tributos-revision',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            });
    }

})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .component('newGfileStep', {
            bindings: {
                gfile: '<',
                stepparent: '=',
                updatetree: '=',
                newstepform: '='
            },
            templateUrl: 'app/entities/g-file-step/new-g-file-step-component.html',
            controller: ['$scope', 'Step', 'StepStatus', 'GFileStep', 'DateUtils','toastr',
                function ($scope, Step, StepStatus, GFileStep, DateUtils, toastr) {
                    var $ctrl = this;

                    $ctrl.$onInit = initialization;
                    $ctrl.save = save;
                    $ctrl.close = close;
                    $ctrl.gfilestep = {};
                    $ctrl.status = [];

                    $scope.$watch('$ctrl.newstepform', function() {
                        if ($ctrl.newstepform === true) {
                            $ctrl.gfilestep = {};
                            calculate_combo_step();
                        }
                    });

                    $scope.$watch('$ctrl.gfilestep.step', function() {
                        if ($ctrl.gfilestep.step && $ctrl.gfilestep.step.status){
                            StepStatus.query({
                                stepId: $ctrl.gfilestep.step.id
                            }).$promise
                                .then(function(data) {
                                    $ctrl.status = data;
                                });
                        }
                    });

                    /**
                     * public methods
                     */
                    /**
                     * @function
                     * @name initialization
                     * @description
                     * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
                     */
                    function initialization() {}

                    function calculate_combo_step() {

                        var stepParentId = null;

                        if($ctrl.stepparent != null) {
                            stepParentId = $ctrl.stepparent.step.id;
                        }

                        var query = {
                            jurisdiction: $ctrl.gfile.jurisdiction,
                            stepParent : stepParentId
                        };

                        Step.query(query)
                            .$promise
                            .then(function(data) {
                                var stepsPosible = data;
                                GFileStep.query({
                                    g_file_id: $ctrl.gfile.id,
                                    allSteps: true
                                }).$promise
                                    .then(function(data) {
                                        $ctrl.steps = [];

                                        for (var i = 0; i < stepsPosible.length; i++) {
                                            var found = false;

                                            for (var j = 0; j < data.length; j++) {
                                                if (stepsPosible[i].name === data[j].title) {
                                                    found = true;
                                                    break;
                                                }
                                            }

                                            if (!found) {
                                                $ctrl.steps.push(stepsPosible[i]);
                                            }
                                        }
                                    });
                            });
                    }


                    function save() {
                        $ctrl.isSaving = true;
                        var gFile = {"id": $ctrl.gfile.id};
                        var gFileStep = Object.assign({}, $ctrl.gfilestep);
                        gFileStep.gfile = gFile;
                        if ($ctrl.stepparent) {
                            gFileStep.stepParent = $ctrl.stepparent.id;
                        }
                        GFileStep.save(gFileStep, onSaveSuccess, onSaveError);
                    }

                    function onSaveSuccess() {
                        toastr.success('Fase creada con éxito');
                        $ctrl.updatetree = true;
                        $ctrl.newstepform = false;
                        $ctrl.isSaving = false;
                    }

                    function onSaveError() {
                        toastr.error('Error al crear la fase');
                        $ctrl.isSaving = false;
                    }

                    function close() {
                        $ctrl.newstepform = false;
                    }

                }
            ]
        });
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('TaskPanelController', TaskPanelController);

    TaskPanelController.$inject = ['$state', 'TaskPanel', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$mdSidenav'];

    function TaskPanelController($state, TaskPanel, ParseLinks, AlertService, paginationConstants, pagingParams, $mdSidenav) {
        var vm = this;
        vm.searchExp = searchExp;
        vm.checkTask = checkTask;

        vm.searchItem = {};

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.untilDate = "3";
        vm.toggleSidenav = buildToggler('left');

        vm.untilDates = [
          {label: "Próximos 3 días", value: "3"},
          {label: "Próxima semana", value: "7"},
          {label: "Próximas 2 semanas", value: "14"},
          {label: "Próximo mes", value: "30"}
        ];

        searchExp();

        function searchExp() {
            TaskPanel.query({
                untilDate: vm.untilDate,
                search: null,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.taskPanel = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function checkTask(taskDto) {
            var task = Object.assign({}, taskDto);
            TaskPanel.update(task);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function buildToggler(navID) {
            return function() {
              // Component lookup should always be available since we are not using `ng-if`
              $mdSidenav(navID).toggle().then(function () {
              });
              };
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('ReportsNewController', ReportsNewController);

    ReportsNewController.$inject = ['$rootScope', '$timeout', '$scope', 'entity', 'Advice', '$state', 'previousState', 'advicestatus', 'toastr', '$mdSidenav'];

    function ReportsNewController($rootScope, $timeout, $scope, entity, Advice,  $state, previousState, advicestatus, toastr, $mdSidenav) {
        var vm = this;

        vm.report = entity;
        vm.clear = clear;
        vm.save = save;
        vm.advicestatus = advicestatus;
        vm.toggleSidenav = buildToggler('left');

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            vm.report.adviceType = 'REPORTS';
            var report = Object.assign({}, vm.report);
            report.adviceType = vm.report.adviceType;
            report.adviceStatus = vm.report.adviceStatus.name;
            report.id = null;
            Advice.save(report, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success('Creado con éxito!', 'Informe');
            vm.isSaving = false;
            $state.go('reports.edit', {
                'id': result.id
            });
        }

        function onSaveError() {
            toastr.error('Error al crear el informe');
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function() {
              // Component lookup should always be available since we are not using `ng-if`
              $mdSidenav(navID).toggle().then(function () {
              });
            };
        }

    }
})();

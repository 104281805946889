(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .component('newNote', {
            bindings: {
                newObservation: '<',
                entitytype: '<',
                entityid: '<',
                onNew: '<',
                cancel: '&',
                save: '&'
            },
            templateUrl: 'app/entities/observation/new-observation-component.html',
            controller: ['$timeout', '$rootScope', '$scope', 'Observation', '$q',
                function($timeout) {
                    var $ctrl = this;

                    $ctrl.onCancel = onCancel;
                    $ctrl.onSave = onSave;
                    $ctrl.initNew = initNew;

                    $timeout(function() {
                        angular.element('#description').focus();
                    });

                    function initNew() {
                        $ctrl.onNew = true;
                        $ctrl.newObservation.entityType = $ctrl.entitytype;
                        $ctrl.newObservation.entityId = $ctrl.entityid;
                        $ctrl.newObservation.type = "NOTE";
                        $ctrl.newObservation.done = false;
                    }

                    function onCancel() {
                        $ctrl.onNew = false;
                        $ctrl.cancel();
                    }

                    function onSave() {
                        $ctrl.onNew = false;
                        $ctrl.save();
                    }
                }
            ]
        });
})();

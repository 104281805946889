(function () {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', 'graphData', 'graphGFileTypeMonth', 'graphGFileTypeYear', 'graphGFileDate', 'graphGFileStep', 'graphNotificationGroupDate', '$state', '$mdSidenav'];

    function HomeController($scope, Principal, LoginService, graphData, graphGFileTypeMonth, graphGFileTypeYear, graphGFileDate, graphGFileStep, graphNotificationGroupDate, $state, $mdSidenav) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.isAdmin = null;
        vm.isClient = null;
        vm.isConsortium = null;
        vm.isJubilar = null;
        vm.login = LoginService.open;
        vm.register = register;
        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                for (var i = 0; i < account.authorities.length; i++) {
                    if (account.authorities[i] === "ROLE_ADMIN") {
                        vm.isAdmin = true;
                    } else if (account.authorities[i] === "ROLE_CLIENT") {
                        vm.isClient = true;
                    } else if (account.authorities[i] === "ROLE_CONSORTIUM") {
                        vm.isConsortium = true;
                    } else if (account.authorities[i] === "ROLE_JUBILAR") {
                        vm.isJubilar = true;
                    }
                }
            });
        }

        function register() {
            $state.go('register');
        }

        vm.colors = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00", "#b82e2e", "#316395", "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac"];

        vm.options = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
            legend: {
                display: true,
                position: "bottom"
            }
        };

        vm.pieOptions = {
            legend: {
                display: true,
                position: "bottom"
            }
        };

        vm.numGFiles = graphData.numGFiles;
        vm.numActiveGFiles = graphData.numActiveGFiles;
        vm.numCivilGFiles = graphData.numCivilGFiles;
        vm.numCivilActiveGFiles = graphData.numCivilActiveGFiles;
        vm.numMercantilGFiles = graphData.numMercantilGFiles;
        vm.numMercantilActiveGFiles = graphData.numMercantilActiveGFiles;
        vm.numContenciosoGFiles = graphData.numContenciosoGFiles;
        vm.numContenciosoActiveGFiles = graphData.numContenciosoActiveGFiles;
        vm.numPenalGFiles = graphData.numPenalGFiles;
        vm.numPenalActiveGFiles = graphData.numPenalActiveGFiles;
        vm.numLaboralGFiles = graphData.numLaboralGFiles;
        vm.numLaboralActiveGFiles = graphData.numLaboralActiveGFiles;
        vm.numPendingNotifications = graphData.numPendingNotifications;
        vm.numTotalAdvices = graphData.numAdvices;
        vm.numActiveAdvices = graphData.numActiveAdvices;
        vm.numTotalReports = graphData.numReports;
        vm.numActiveReports = graphData.numActiveReports;
        vm.numExpiredTasks = graphData.numExpiredTasks;
        vm.numExpiredTasksSoon = graphData.numExpiredTasksSoon;

        vm.numTotalCExpedientes = graphData.numTotalCExpedientes;
        vm.numActiveCExpedientes = graphData.numActiveCExpedientes;

        vm.numDonantesJubilar = graphData.numDonantesJubilar;
        vm.numAportantesJubilar = graphData.numAportantesJubilar;
        vm.numConveniosJubilar = graphData.numConveniosJubilar;

        vm.gfiletypeMonth = {};
        vm.gfiletypeMonth.label = graphGFileTypeMonth.label;
        vm.gfiletypeMonth.series = graphGFileTypeMonth.series;
        vm.gfiletypeMonth.data = graphGFileTypeMonth.values;

        vm.gfiletypeYear = {};
        vm.gfiletypeYear.label = graphGFileTypeYear.label;
        vm.gfiletypeYear.series = graphGFileTypeYear.series;
        vm.gfiletypeYear.data = graphGFileTypeYear.values;

        vm.gfileDate = {};
        vm.gfileDate.label = graphGFileDate.label;
        vm.gfileDate.series = graphGFileDate.series;
        vm.gfileDate.data = graphGFileDate.values;

        vm.gfileStep = {};
        vm.gfileStep.label = graphGFileStep.label;
        vm.gfileStep.data = graphGFileStep.pieValues;

        vm.notificationDate = {};
        vm.notificationDate.label = graphNotificationGroupDate.label;
        vm.notificationDate.series = graphNotificationGroupDate.series;
        vm.notificationDate.data = graphNotificationGroupDate.values;
        vm.toggleSidenav = buildToggler('left');
        vm.onClick = function (points, evt) {
            console.log(points, evt);
        };

        function buildToggler(navID) {
            return function() {
              // Component lookup should always be available since we are not using `ng-if`
              $mdSidenav(navID).toggle().then(function () {
              });
              };
        }

    }
})();

(function() {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('GFileCode', GFileCode);

    GFileCode.$inject = ['$resource'];

    function GFileCode ($resource) {
        var resourceUrl =  'api/g-files-code';

        return $resource(resourceUrl, {}, {
          'get': {
              method: 'GET',
              transformResponse: function (data) {
                  if (data) {
                      data = angular.fromJson(data);
                  }
                  return data;
              }
          }
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('GFileContenciosoNewController', GFileContenciosoNewController);

    GFileContenciosoNewController.$inject = ['$rootScope', '$timeout', '$scope', 'entity', 'GFile', 'lawyers', '$state', 'previousState',  'processtypes', 'clients', 'toastr', 'DateUtils', '$mdSidenav'];

    function GFileContenciosoNewController($rootScope, $timeout, $scope, entity, GFile, lawyers, $state, previousState, processtypes, clients ,toastr, DateUtils, $mdSidenav) {
        var vm = this;

        vm.gFile = entity;
        vm.clear = clear;
        vm.save = save;
        vm.processtypes = processtypes;
        vm.clients = clients;
        vm.lawyers = lawyers;
        vm.toggleSidenav = buildToggler('left');
        vm.gFile.code = '';

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            vm.gFile.jurisdiction = 'CONTENCIOSA';
            var gFile = Object.assign({}, vm.gFile);
            if (gFile.registerDate !== null) {
                gFile.registerDate = DateUtils.convertLocalDateToServer(gFile.registerDate);
            }
            if (gFile.notificationDate !== null) {
                gFile.notificationDate = DateUtils.convertLocalDateToServer(gFile.notificationDate);
            }
            if (gFile.presentationDate !== null) {
                gFile.presentationDate = DateUtils.convertLocalDateToServer(gFile.presentationDate);
            }
            gFile.client = vm.gFile.client.name;
            gFile.id = null;
            GFile.save(gFile, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success('Creado con éxito!', 'Expediente ' + result.code);
            vm.isSaving = false;
            $state.go('g-file-contencioso.edit', {
                'id': result.id
            });
        }

        function onSaveError() {
            toastr.error('Error al crear el expediente');
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function() {
              // Component lookup should always be available since we are not using `ng-if`
              $mdSidenav(navID).toggle().then(function () {
              });
              };
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .component('editTask', {
            bindings: {
                editTask: '<',
                cancel: '&',
                update: '&',
                delete: '&'
            },
            templateUrl: 'app/entities/task/edit-task-component.html',
            controller: ['$timeout',
                function($timeout) {
                    var $ctrl = this;

                    $ctrl.onCancel = onCancel;
                    $ctrl.onUpdate = onUpdate;
                    $ctrl.onDelete = onDelete;

                    $timeout(function() {
                        angular.element('#description').focus();
                    });

                    function onUpdate() {
                        $ctrl.update();
                    }

                    function onCancel() {
                        $ctrl.cancel();
                    }

                    function onDelete() {
                        $ctrl.delete();
                    }
                }
            ]
        });
})();

(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller("LebaniegoYearResultSummaryNewController", LebaniegoYearResultSummaryNewController);

        LebaniegoYearResultSummaryNewController.$inject = [
        "$timeout",
        "$state",
        "$mdSidenav",
        "$q",
        "entity",
        "LebaniegoYearResultSummary",
        "previousState",
        "toastr"
    ]

    function LebaniegoYearResultSummaryNewController(
        $timeout,
        $state,
        $mdSidenav,
        $q,
        entity,
        LebaniegoYearResultSummary,
        previousState,
        toastr
    ) {
        var vm = this;

        vm.lebaniegoYearResultSummary = entity;
        vm.clear = clear;
        vm.save = save;
        vm.toggleSidenav = buildToggler("left");

        // Retrieve the value(s)
        vm.searchItem = JSON.parse(
            localStorage.getItem("searchLebaniegoYearResultSummaryParameters")
        );
        
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            var lebaniegoYearResultSummary = Object.assign({}, vm.lebaniegoYearResultSummary);
            lebaniegoYearResultSummary.id = null;
            lebaniegoYearResultSummary.year = vm.lebaniegoYearResultSummary.year;
            lebaniegoYearResultSummary.gFile = null;
            lebaniegoYearResultSummary.sector = 'RESUMEN';
            LebaniegoYearResultSummary.save(lebaniegoYearResultSummary, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success("Creada con éxito!");
            vm.isSaving = false;
            $state.go("lebaniego-result-summary", {});
        }

        function onSaveError() {
            toastr.error("Error al crear el resumen");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                $mdSidenav(navID).toggle().then(function () {});
            }
        }
    }
})();
(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller("LebaniegoYearDocumentosAccionNewController", LebaniegoYearDocumentosAccionNewController);

        LebaniegoYearDocumentosAccionNewController.$inject = [
        "$rootScope",
        "$timeout",
        "$scope",
        "entity",
        "LebaniegoYearDocumentosAccion",
        "$state",
        "previousState",
        "toastr",
        "DateUtils",
        "$mdSidenav",
    ]

    function LebaniegoYearDocumentosAccionNewController(
        $rootScope,
        $timeout,
        $scope,
        entity,
        LebaniegoYearDocumentosAccion,
        $state,
        previousState,
        toastr,
        DateUtils,
        $mdSidenav
    ) {
        var vm = this;

        vm.lebaniegoYearDocumentosAccion = entity;
        vm.clear = clear;
        vm.save = save;
        vm.toggleSidenav = buildToggler("left");

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            var lebaniegoYearDocumentosAccion = Object.assign({}, vm.lebaniegoYearDocumentosAccion);
            lebaniegoYearDocumentosAccion.id = null;
            LebaniegoYearDocumentosAccion.save(lebaniegoYearDocumentosAccion, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success("Creada con éxito!");
            vm.isSaving = false;
            $state.go("lebaniego-documents-accion", {});
        }

        function onSaveError() {
            toastr.error("Error al crear el accion");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                $mdSidenav(navID).toggle().then(function () {});
            }
        }
    }
})();
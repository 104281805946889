(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .component('editDocument', {
            bindings: {
                gfilestep: '<',
                gfile: '<',
                updatetree: '=',
                editdocumentform: '=',
                document: '='
            },
            templateUrl: 'app/entities/document/edit-document-component.html',
            controller: ['$scope', 'Document', 'Upload', '$http', 'DateUtils', 'toastr',
                function ($scope, Document,  Upload, $http, DateUtils, toastr) {
                    var $ctrl = this;

                    $ctrl.$onInit = initialization;
                    $ctrl.save = save;
                    $ctrl.close = close;
                    $ctrl.uploadfile = uploadfile;
                    $ctrl.downloadfile = downloadfile;
                    $ctrl.remove_document = remove_document;

                    $ctrl.document = {};

                    function initialization() {

                    }

                    function save() {
                        $ctrl.isSaving = true;
                        var gFileStep = {"id": $ctrl.document.gFileStep.id};
                        var document = Object.assign({}, $ctrl.document);
                        document.gFileStep = gFileStep;
                        Document.update(document, onSaveSuccess, onSaveError);
                    }

                    function close() {
                        $ctrl.editdocumentform = false;
                    }

                    function uploadfile(file, errFiles) {
                        if (file) {
                            $ctrl.file = file;
                            $ctrl.previousFile = $ctrl.document.path;
                            $ctrl.document.filename = $ctrl.file.name;
                        }
                    }

                    function upload() {
                        Upload.upload({
                            url: "api/g-files/upload",
                            data: {
                                file: $ctrl.file,
                                name: $ctrl.file.name,
                                previousFile: $ctrl.previousFile,
                                gFileID: $ctrl.gfile.id,
                                documentID: $ctrl.document.id
                            },
                            method: 'POST'
                        }).then(
                            function() {
                                $ctrl.isSaving = false;
                                $ctrl.updatetree = true;
                                $ctrl.editdocumentform = false;
                                toastr.success('Documento actualizado con éxito');
                                Document.get({id: $ctrl.document.id}, onUploadSuccess, onUploadError);
                            },
                            function() {
                                $ctrl.isSaving = false;
                                toastr.error('Error al subir el documento');
                            },
                            function() {});
                    }

                    function onUploadSuccess(result) {
                      $ctrl.document = result;
                    }

                    function onUploadError() {
                        $ctrl.isSaving = false;
                    }

                    function onSaveSuccess(result) {
                        if ($ctrl.file) {
                          upload();
                        }
                        else {
                          $ctrl.isSaving = false;
                          toastr.success('Documento actualizado con éxito');
                        }
                        $ctrl.updatetree = true;
                        $ctrl.editdocumentform = false;
                        $ctrl.isSaving = false;
                    }

                    function onSaveError() {
                        $ctrl.isSaving = false;
                        toastr.error('Error al actualizar el documento');
                    }

                    function remove_document() {
                        Document.delete({
                                id: $ctrl.document.id
                            },
                            function() {
                                toastr.success('Documento eliminado con éxito');
                                $ctrl.updatetree = true;
                                $ctrl.editdocumentform = false;
                            },
                            function () {
                                toastr.error('Error al eliminar el documento');
                                $ctrl.updatetree = true;
                                $ctrl.editdocumentform = false;
                            });
                    }

                    function downloadfile(documentId, fileType) {
                        $http({
                            method: 'GET',
                            url: 'api/g-files/download/' + $ctrl.document.id,
                            responseType: 'arraybuffer',
                            cache: false
                        }).success(function(data) {
                            var a = document.createElement('a');
                            var blob = new Blob([data], {
                                'type': "application/octet-stream"
                            });
                            a.href = URL.createObjectURL(blob);
                            a.download = $ctrl.document.filename;
                            a.click();
                        }).error(function(data, status, headers, config) {

                        });
                    }

                }
            ]
        });
})();

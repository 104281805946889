(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller(
            "GFileContenciosoDetailController",
            GFileContenciosoDetailController
        );

    GFileContenciosoDetailController.$inject = [
        "$scope",
        "$rootScope",
        "$http",
        "previousState",
        "entity",
        "Principal",
        "GFile",
        "GFileStep",
        "$state",
        "Document",
        "DateUtils",
        "processtypes",
        "status",
        "Observation",
        "toastr",
        "lawyers",
        "clients",
        "$mdSidenav",
    ];

    function GFileContenciosoDetailController(
        $scope,
        $rootScope,
        $http,
        previousState,
        entity,
        Principal,
        GFile,
        GFileStep,
        $state,
        Document,
        DateUtils,
        processtypes,
        status,
        Observation,
        toastr,
        lawyers,
        clients,
        $mdSidenav
    ) {
        var vm = this;

        vm.gFile = entity;

        vm.clients = clients;
        vm.lawyers = lawyers;
        vm.gfilestep = {};
        vm.file_type = "CONTENCIOSA";
        vm.toggleSidenav = buildToggler("left");
        vm.save = save;

        vm.isdeletedstep = false;
        vm.edit_gstep = edit_gstep;
        vm.processtypes = processtypes;
        vm.status = status;

        vm.openNewStepForm = openNewStepForm;
        vm.download_file = downloadfile;
        vm.new_document = new_document;
        vm.edit_document = edit_document;
        vm.previousState = previousState.name;
        vm.newstepform = false;
        vm.editdocumentform = false;
        vm.entityType = "GF";
        vm.list = [];
        vm.updatetree = false;
        vm.nodes = [];

        $scope.$watch("vm.updatetree", function () {
            if (vm.updatetree === true) {
                loadGFileSteps();
            }
            vm.updatetree = false;
        });

        var unsubscribe = $rootScope.$on(
            "expedientesUriarteApp:gFileUpdate",
            function (event, result) {
                vm.gFile = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);

        Principal.hasAuthority("ROLE_ADMIN").then(function (result) {
            vm.isAdmin = result;
        });

        loadGFileSteps();
        hasNotes();

        function loadGFileSteps() {
            GFileStep.query(
                {
                    g_file_id: vm.gFile.id,
                    allSteps: false,
                },
                onSuccess,
                onError
            );

            function onSuccess(data) {
                vm.list = [];
                if (data.length >= 1) {
                    vm.list = data;
                    vm.nodes = data;
                }
            }

            function onError(error) {}
        }

        function save() {
            vm.isSaving = true;
            var gFile = Object.assign({}, vm.gFile);
            gFile.client = vm.gFile.client.name;
            GFile.update(gFile, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success(
                "Actualizado con éxito!",
                "Expediente " + result.code
            );
            vm.isSaving = false;
        }

        function onSaveError() {
            toastr.error("Error al actualizar el expediente");
            vm.isSaving = false;
        }

        function openNewStepForm(stepParent) {
            if (stepParent) {
                GFileStep.get(
                    {
                        id: stepParent,
                    },
                    onNewGStepSuccess,
                    onNewGStepError
                );
            } else {
                vm.newstepform = true;
                vm.stepParent = null;
                vm.editstepform = false;
                vm.newdocumentform = false;
                vm.editdocumentform = false;
            }
        }

        function onNewGStepSuccess(data) {
            vm.stepParent = data;
            vm.newstepform = true;
            vm.editstepform = false;
            vm.newdocumentform = false;
            vm.editdocumentform = false;
        }
        function onNewGStepError() {
            //TODO
        }

        function edit_gstep(gstep_id, isdeleted) {
            vm.isdeletedstep = isdeleted;
            vm.gfilestep = {};
            GFileStep.get(
                {
                    id: gstep_id,
                },
                onEditGStepSuccess,
                onEditGStepError
            );
        }

        function onEditGStepSuccess(data) {
            vm.gfilestep = data;
            vm.editstepform = true;
            $scope.isDeleting = false;

            vm.newstepform = false;
            vm.newdocumentform = false;
            vm.editdocumentform = false;
        }

        function onEditGStepError() {
            //TODO
        }

        function downloadfile(documentId, fileType) {
            Document.get(
                {
                    id: documentId,
                },
                function (data) {
                    $http({
                        method: "GET",
                        url: "api/g-files/download/" + documentId,
                        responseType: "arraybuffer",
                        cache: false,
                    })
                        .success(function (result) {
                            var a = document.createElement("a");
                            var blob = new Blob([result], {
                                type: "application/octet-stream",
                            });
                            a.href = URL.createObjectURL(blob);

                            a.download = data.filename;
                            a.click();
                        })
                        .error(function (data, status, headers, config) {});
                }
            );
        }

        function new_document(gstep_id) {
            GFileStep.get(
                {
                    id: gstep_id,
                },
                onNewDocumentSuccess,
                onNewDocumentError
            );
        }

        function onNewDocumentSuccess(data) {
            vm.gfilestep = data;
            vm.newdocumentform = true;
            vm.newstepform = false;
            vm.editstepform = false;
            vm.editdocumentform = false;
        }

        function onNewDocumentError() {
            //TODO
        }

        function edit_document(document_id) {
            Document.get(
                {
                    id: document_id,
                },
                onEditDocumentSuccess,
                onEditDocumentError
            );
        }

        function onEditDocumentSuccess(data) {
            vm.document = data;
            vm.editdocumentform = true;
            vm.newstepform = false;
            vm.editstepform = false;
            vm.newdocumentform = false;
        }

        function onEditDocumentError() {
            //TODO
        }

        function hasNotes() {
            Observation.query(
                {
                    gFile: vm.gFile.id,
                },
                onSuccess,
                onError
            );

            function onSuccess(data) {
                $rootScope.hasNotes = data.length;
            }

            function onError() {
                $rootScope.hasNotes = 0;
            }
        }

        function buildToggler(navID) {
            return function () {
                // Component lookup should always be available since we are not using `ng-if`
                $mdSidenav(navID)
                    .toggle()
                    .then(function () {});
            };
        }
    }
})();

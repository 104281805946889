(function() {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('ConsortiumTributosAeat', ConsortiumTributosAeat);

        ConsortiumTributosAeat.$inject = ['$resource', 'DateUtils'];

    function ConsortiumTributosAeat ($resource, DateUtils) {
        var resourceUrl =  'api/consortium-tributos-aeat/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': { method:'PUT' 
            },
            'save': {
                method: 'POST'   
            }
        });
    }
})();

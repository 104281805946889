(function() {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('AdviceCsv', AdviceCsv);

    AdviceCsv.$inject = ['$resource'];

    function AdviceCsv ($resource) {
        var resourceUrl =  'api/advices-csv';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

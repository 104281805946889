(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .directive('phoneNumber', phoneNumber);

    var phonePattern = /^(\+34|0034|34)?[\s|\-|\.]?[6|7|8|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/;
    
    function phoneNumber () {
        return {
            require: 'ngModel',
            link: function (scope, elm, attrs, ctrl) {
                ctrl.$validators.phoneNumber = function (modelValue, viewValue) {
                    if (ctrl.$isEmpty(modelValue)) {
                        return true;
                    }

                    if (phonePattern.test(viewValue)) {
                        return true;
                    }

                    return false;
                };
            }
        };
    }
})();

(function() {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('AmountCsv', AmountCsv);

        AmountCsv.$inject = ['$resource'];

    function AmountCsv ($resource) {
        var resourceUrl =  'api/ly-amount-csv';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET' }
        });
    }
})();

(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller(
            "LebaniegoYearAmountController",
            LebaniegoYearAmountController
        );

    LebaniegoYearAmountController.$inject = [
        "DateUtils",
        "$state",
        "LebaniegoYearAmount",
        "ParseLinks",
        "AlertService",
        "paginationConstants",
        "pagingParams",
        "$mdSidenav",
        "AmountCsv",
    ];

    function LebaniegoYearAmountController(
        DateUtils,
        $state,
        LebaniegoYearAmount,
        ParseLinks,
        AlertService,
        paginationConstants,
        pagingParams,
        $mdSidenav,
        AmountCsv
    ) {
        var vm = this;

        vm.searchExp = searchExp;
        vm.deleteSearchItem = deleteSearchItem;

        vm.toggleSidenav = buildToggler("left");

        // Retrieve the value(s)
        vm.searchItem = JSON.parse(localStorage.getItem("searchParameters"));

        if (vm.searchItem === null) {
            vm.searchItem = {};
            vm.searchItem.year = new Date().getFullYear();
        }

        vm.jurisdictions = [
            "Todas las cuantías",
            "Cuantías donantes",
            "Cuantías aportadas",
            "Cuantías ingresadas por convenio",
        ];

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.csvOrder = ["year", "businessName", "amount"];
        vm.getHeader = function () {
            return ["Año", "Razón Social", "Cuantía"];
        };

        searchExp();

        function searchExp() {
            localStorage.setItem(
                "searchParameters",
                JSON.stringify(vm.searchItem)
            );

            LebaniegoYearAmount.query(
                {
                    year: vm.searchItem.year,
                    jurisdiction: vm.searchItem.jurisdiction,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                },
                onSuccess,
                onError
            );

            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    result.push("id");
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.amounts = data.listAmountDTO.content;
                vm.totalAmount = data.total;
                vm.page = pagingParams.page;

                if (vm.amounts.length > 0) {
                    vm.amounts.forEach(element => {
                        if (element.jurisdiction == "AÑO_LEBANIEGO_DONANTES") {
                            element.type = "Cuantías donantes";
                        } else if (element.jurisdiction == "AÑO_LEBANIEGO_APORTANTES") {
                            element.type = "Cuantías aportadas";
                        } else {
                            element.type = "Cuantías ingresadas por convenio";
                        }
                    });
                }

                console.log(vm.amounts);
                vm.filename = getFilename();
                getExportData();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getFilename() {
            var today = new Date();

            var dd = today.getDate();
            if (dd < 10) dd = "0" + dd;

            var mm = today.getMonth() + 1;
            if (mm < 10) mm = "0" + mm;

            var yy = today.getFullYear();

            return "export_cuantías_" + yy + mm + dd + ".csv";
        }

        function deleteSearchItem() {
            vm.searchItem = {};
            vm.searchItem.year = new Date().getFullYear();

            localStorage.setItem(
                "searchParameters",
                JSON.stringify(vm.searchItem)
            );
            vm.page = 1;
            vm.transition();
            searchExp();
        }

        function getExportData() {
            AmountCsv.query({
                year: vm.searchItem.year,
                jurisdiction: vm.searchItem.jurisdiction,
            },
            function (data) {
                vm.exportData = data.listAmountDTO.content;
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: vm.currentSearch,
            });
        }

        function buildToggler(navID) {
            return function () {
                // Component lookup should always be available since we are not using `ng-if`
                $mdSidenav(navID)
                    .toggle()
                    .then(function () {});
            };
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('g-file-penal', {
                parent: 'entity',
                url: '/g-file-penal?page&sort&search&code&type&nif&name&sn1&sn2&company_name',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CLIENT'],
                    pageTitle: 'GFiles'
                },
                views: {
                  'content@': {
                    templateUrl: 'app/entities/g-file-penal/g-files.html',
                    controller: 'GFilePenalController',
                    controllerAs: 'vm'
                  }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    steps: ['$stateParams', 'Step', function($stateParams, Step) {
                        return Step.query().$promise;
                    }],
                    status: ['$stateParams', 'GFileStatus', function($stateParams, GFileStatus) {
                        return GFileStatus.query().$promise;
                    }]
                }
            })
            .state('g-file-penal.edit', {
                parent: 'g-file-penal',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CLIENT'],
                    pageTitle: 'GFile'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/g-file-penal/g-file-detail.html',
                        controller: 'GFilePenalDetailController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                      label: 'Administrar',
                      parent: 'g-file-penal'
                },
                resolve: {
                    entity: ['$stateParams', 'GFile', function($stateParams, GFile) {
                        return GFile.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    processtypes: ['$stateParams', 'ProcessType', function($stateParams, ProcessType) {
                        return ProcessType.query({jurisdiction: 'PENAL'}).$promise;
                    }],
                    clients: ['$stateParams', 'GFileClients', function($stateParams, GFileClients) {
                        return GFileClients.query().$promise;
                    }],
                    lawyers: ['$stateParams', 'Lawyers', function($stateParams, Lawyers) {
                        return Lawyers.query().$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'g-file',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    status: ['$stateParams', 'GFileStatus', function($stateParams, GFileStatus) {
                        return GFileStatus.query().$promise;
                    }]
                }
            })
            .state('g-file-penal.new', {
                parent: 'g-file-penal',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                  'content@': {
                    templateUrl: 'app/entities/g-file-penal/g-file-new.html',
                    controller: 'GFilePenalNewController',
                    controllerAs: 'vm'
                  }
                },
                ncyBreadcrumb: {
                      label: 'Nuevo',
                      parent: 'g-file-penal'
                },
                resolve: {
                    entity: function() {
                        return {
                            code: null,
                            id: null
                        };
                    },
                    processtypes: ['$stateParams', 'ProcessType', function($stateParams, ProcessType) {
                        return ProcessType.query({jurisdiction: 'PENAL'}).$promise;
                    }],
                    clients: ['$stateParams', 'GFileClients', function($stateParams, GFileClients) {
                        return GFileClients.query().$promise;
                    }],
                    lawyers: ['$stateParams', 'Lawyers', function($stateParams, Lawyers) {
                        return Lawyers.query().$promise;
                    }],
                    previousState: ["$state", function ($state) {
                          var currentStateData = {
                              name: $state.current.name || 'g-file',
                              params: $state.params,
                              url: $state.href($state.current.name, $state.params)
                          };
                          return currentStateData;
                    }]
                }
            });
    }

})();

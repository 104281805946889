(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$state', 'entity', 'User', 'toastr', '$mdSidenav'];

    function UserManagementDialogController ($state, entity, User, toastr, $mdSidenav) {
        var vm = this;

        vm.authorities = ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_CONSORTIUM', 'ROLE_JUBILAR'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;
        vm.deleteUser = deleteUser;
        vm.toggleSidenav = buildToggler('left');

        function clear () {
            $state.go('user-management');
        }

        function onSaveSuccess (result) {
            toastr.success('Actualizado con éxito!', 'Usuario ' + result.login);

            $state.go('user-management.edit', {
                'login': result.login
            });

            vm.isSaving = false;
        }

        function onSaveError () {
            toastr.error('Error al actualizar usuario');
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                vm.user.langKey = 'en';
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function deleteUser() {
            User.delete({login: vm.user.login},
                function() {
                    toastr.success('Usuario eliminado con éxito');
                    $state.go('user-management');
                },
                function() {
                    toastr.error('Error al eliminar el usuario');
                }
            );
        }
        
        function buildToggler(navID) {
            return function() {
              // Component lookup should always be available since we are not using `ng-if`
              $mdSidenav(navID).toggle().then(function () {
              });
            };
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .component('adviceDocuments', {
            bindings: {
                advice: '<'
            },
            templateUrl: 'app/entities/advices/documents/advice-documents.component.html',
            controller: ['$timeout', '$rootScope', '$scope', 'AdviceDocument', '$q', 'toastr', 'DateUtils', '$http', 'Principal',
                function($timeout, $rootScope, $scope, AdviceDocument, $q, toastr, DateUtils, $http, Principal) {
                    var $ctrl = this;
                    $ctrl.document = {};
                    $ctrl.documents = {};
                    $ctrl.$onInit = onInit;
                    $ctrl.openNewAdviceDocument = openNewAdviceDocument;
                    $ctrl.editAdviceDocument = editAdviceDocument;
                    $ctrl.action = "query";
                    $ctrl.downloadFile = downloadFile;
                    $ctrl.$onChanges = function(changes) {
                        retrieveDocuments();
                    };
                    Principal.hasAuthority("ROLE_ADMIN")
                    .then(function (result) {
                        $ctrl.isAdmin = result;
                    });
                    $scope.$watch('$ctrl.action', function() {
                        if ($ctrl.action === 'query') {
                            retrieveDocuments();
                        }
                    });

                    function downloadFile(documentId) {
                        AdviceDocument.get({
                            id: documentId
                        }, function(data) {
                            $http({
                                method: 'GET',
                                url: 'api/advice-documents/download/' + documentId,
                                responseType: 'arraybuffer',
                                cache: false
                            }).success(function (result) {
                                var a = document.createElement('a');
                                var blob = new Blob([result], {
                                    'type': "application/octet-stream"
                                });
                                a.href = URL.createObjectURL(blob);
                                var path_file = "/opt/expedientes_uriarte_repo/" + data.advice + "/";
                                var full_file = data.path;

                                a.download = full_file.slice(full_file.search(path_file) + path_file.length, full_file.length);
                                a.click();
                            }).error(function (data, status, headers, config) {

                            });
                        });
                    }

                    function onInit() {
                        retrieveDocuments();
                    }

                    function openNewAdviceDocument() {
                      $ctrl.action = "new";
                    }

                    function editAdviceDocument(adviceDocument) {
                      AdviceDocument.get({
                        id: adviceDocument.id
                      }, onEditDocumentSuccess, onEditDocumentError);
                    }

                    function onEditDocumentSuccess(data) {
                        $ctrl.action = 'edit';
                        $ctrl.document = data;
                    }

                    function onEditDocumentError() {
                    }

                    function retrieveDocuments() {
                        $q.all([
                                AdviceDocument.query({
                                    adviceId: $ctrl.advice.id
                                }).$promise
                            ])
                            .then(function(data) {
                                $ctrl.documents = data[0];
                            });
                    }
                }
            ]
        });
})();

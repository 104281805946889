(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('ConsortiumPersonalNominaDetailController', ConsortiumPersonalNominaDetailController);

        ConsortiumPersonalNominaDetailController.$inject = ['$mdDialog', 'entity', 'Principal', 'ConsortiumPersonalNomina', 'toastr', '$mdSidenav', '$state', 'previousState', 'ConsortiumPersonalNominaFile', 'ConsortiumUsers', '$http', 'Upload' ];

    function ConsortiumPersonalNominaDetailController($mdDialog, entity, Principal, ConsortiumPersonalNomina, toastr, $mdSidenav, $state, previousState, ConsortiumPersonalNominaFile, ConsortiumUsers, $http, Upload) {
        var vm = this;

        vm.consortiumPersonalNomina = entity;
        vm.clear = clear;

        vm.toggleSidenav = buildToggler('left');
        vm.save = save;

        vm.months = ["ENERO","FEBRERO","MARZO","ABRIL","MAYO","JUNIO","JULIO","AGOSTO","SEPTIEMBRE","OCTUBRE","NOVIEMBRE","DICIEMBRE"];

        vm.updateDocument = updateDocument;
        vm.downloadDocument = downloadDocument;
        vm.removeDocument = removeDocument;
        vm.uploadDocument = uploadDocument;
        vm.showConfirm = showConfirm;

        vm.updatetree = false;
        vm.nodes = [];

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        Principal.hasAuthority("ROLE_ADMIN").then(function (result) {
            vm.isAdmin = result;
        });

        ConsortiumPersonalNominaFile.query({
            id: vm.consortiumPersonalNomina.id
        }, onSuccess, onError);
        
        ConsortiumUsers.query({}, onConsortiumSuccess, onError);
        
        function onSuccess(data, headers) {
            vm.documents = data;
        }

        function onConsortiumSuccess(data, headers) {
            vm.consortiumUsers = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function save() {
            vm.isSaving = true;
            var consortiumPersonalNomina = Object.assign({}, vm.consortiumPersonalNomina);
            ConsortiumPersonalNomina.update(consortiumPersonalNomina, onSaveSuccess, onSaveError);
        }

        function updateDocument(document) {
            ConsortiumPersonalNominaFile.update(document, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            toastr.success('Guardado con éxito!');
            vm.isSaving = false;
        }

        function onSaveError() {
            toastr.error('Error al guardar');
            vm.isSaving = false;
        }

        function downloadDocument(documentId) {
            $http({
                method: 'GET',
                url: 'api/consortium-personal-nomina-files/download/' + documentId,
                responseType: 'arraybuffer',
                cache: false
            }).success(function(data) {
                var a = document.createElement('a');
                var blob = new Blob([data], {
                    'type': "application/octet-stream"
                });
                a.href = URL.createObjectURL(blob);
                var documentName = "";
                for (var i = 0; i < vm.documents.length; i++) {
                    var doc = vm.documents[i];
                    if (doc.id === documentId) {
                        documentName = doc.fileName;
                    }
                }
                a.download = documentName;
                a.click();
            }).error(function(data, status, headers, config) {
                toastr.error('Error al descargar el documento');
            });
        }

        function showConfirm(documentId) {
            var confirm = $mdDialog.confirm()
              .title('¿Desea borrar el documento?')
              .textContent('Si elimina el documento no se podrá recuperar.')
              .ok('Borrar')
              .cancel('Cancelar');
        
            $mdDialog.show(confirm).then(function () {
                removeDocument(documentId);
            }, function () {
              
            });
        }

        function removeDocument(documentId) {
            ConsortiumPersonalNominaFile.delete({
                id: documentId
            },
            function() {
                toastr.success('Documento eliminado con éxito');
                ConsortiumPersonalNominaFile.query({
                    id: vm.consortiumPersonalNomina.id
                }, onSuccess, onError);
            },
            function () {
                toastr.error('Error al eliminar el documento');
            });
        }

        function uploadDocument(file, errFiles) {
            if (file) {
                Upload.upload({
                    url: "api/consortium-personal-nomina-files",
                    data: {
                        file: file,
                        fileName: file.name,
                        consortiumPersonalNominaId: vm.consortiumPersonalNomina.id
                    },
                    method: 'POST'
                }).then(
                    function() {
                        toastr.success('Documento subido con éxito');
                        ConsortiumPersonalNominaFile.query({
                            id: vm.consortiumPersonalNomina.id
                        }, onSuccess, onError);
                    },
                    function(response) {
                        toastr.error('Error al subir el documento');
                    },
                    function() {}
                );              

            }
        }

        function buildToggler(navID) {
            return function() {
              // Component lookup should always be available since we are not using `ng-if`
              $mdSidenav(navID).toggle().then(function () {
              });
              };
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .component('newAdviceDocument', {
            bindings: {
                advice: '<',
                action: '='
            },
            templateUrl: 'app/entities/advices/documents/new-advice-document.component.html',
            controller: ['$scope', 'Upload', 'AdviceDocument',  'toastr',
                function ($scope, Upload, AdviceDocument,  toastr) {
                    var $ctrl = this;

                    $ctrl.$onInit = initialization;
                    $ctrl.save = save;
                    $ctrl.uploadfile = uploadfile;
                    $ctrl.close = close;

                    $ctrl.document = {};

                    $scope.$watch('$ctrl.newdocumentform', function() {
                        if ($ctrl.newdocumentform === true) {
                            $ctrl.document = {};
                        }
                    });

                    function initialization() {

                    }

                    function save() {
                        $ctrl.isSaving = true;
                        $ctrl.document.signed = false;
                        var document = Object.assign({}, $ctrl.document);
                        document.advice = $ctrl.advice.id;
                        AdviceDocument.save(document, onSaveSuccess, onSaveError);
                    }

                    function uploadfile(file) {
                      if (file) {
                        $ctrl.file = file;
                        $ctrl.document.path = "/opt/expedientes_uriarte_repo/" + $ctrl.advice.id + "/" + file.name;
                      }
                    }

                    function upload() {
                      Upload.upload({
                                url: "api/advices-documents/upload",
                                data: {
                                    file: $ctrl.file,
                                    name: $ctrl.file.name,
                                    previousFile: "",
                                    adviceId: $ctrl.advice.id,
                                    documentID: $ctrl.document.id
                                },
                                method: 'POST'
                            }).then(
                                function() {
                                    $ctrl.isSaving = false;
                                    toastr.success('Documento subido con éxito');
                                    close();
                                },
                                function(response) {
                                    $ctrl.isSaving = false;
                                    toastr.error('Error al subir el documento');
                                },
                                function() {});
                    }

                    function onSaveSuccess(result) {
//                        toastr.success('Documento creado con éxito');
                        $ctrl.updatetree = true;
                        $ctrl.newdocumentform = false;
                        $ctrl.editdocumentform = false;
                        $ctrl.isSaving = false;
                        $ctrl.document = result;

                        upload();
                    }

                    function onSaveError() {
                        toastr.error('Error al crear el documento');
                        $ctrl.isSaving = false;
                    }

                    function close() {
                        $ctrl.document = {};
                        $ctrl.action = 'query';
                    }

                }
            ]
        });
})();

(function () {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('ConsortiumPersonalNominaFile', ConsortiumPersonalNominaFile);

    ConsortiumPersonalNominaFile.$inject = ['$resource', 'DateUtils'];

    function ConsortiumPersonalNominaFile($resource, DateUtils) {
        var resourceUrl = 'api/consortium-personal-nomina-files/:id';

        return $resource(resourceUrl, {}, {
            'query': { 
                method: 'GET', 
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.forEach(document => {
                            if (document.date) {
                                document.date = DateUtils.convertLocalDateFromServer(document.date);
                            }
                        })
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    if (copy.date !== null) {
                        copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    }
                    return angular.toJson(copy);
                }
            },
            'delete': { method: 'DELETE' }
        });
    }
})();

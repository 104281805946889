(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller(
            "LebaniegoYearControlDonorDetailController",
            LebaniegoYearControlDonorDetailController
        );

    LebaniegoYearControlDonorDetailController.$inject = [
        "$rootScope",
        "$mdSidenav",
        "$scope",
        "$http",
        "$q",
        "$mdDialog",
        "previousState",
        "entity",
        "Principal",
        "LebaniegoYearControlDonor",
        "LebaniegoYearControlDonorFile",
        "$state",
        "toastr",
        "Upload"
    ];

    function LebaniegoYearControlDonorDetailController(
        $rootScope,
        $mdSidenav,
        $scope,
        $http,
        $q,
        $mdDialog,
        previousState,
        entity,
        Principal,
        LebaniegoYearControlDonor,
        LebaniegoYearControlDonorFile,
        $state,
        toastr,
        Upload
    ) {
        var vm = this;

        vm.lebaniegoYearControlDonor = entity;
        vm.actualBusinessName = vm.lebaniegoYearControlDonor.gFile.sectorBusinessName;

        vm.toggleSidenav = buildToggler("left");
        vm.save = save;
        vm.clear = clear;

        vm.list = [];
        vm.nodes = [];

        vm.updateDocument = updateDocument;
        vm.downloadDocument = downloadDocument;
        vm.removeDocument = removeDocument;
        vm.uploadDocument = uploadDocument;
        vm.showConfirm = showConfirm;

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        Principal.hasAuthority("ROLE_ADMIN").then(function (result) {
            vm.isAdmin = true;
        });
        Principal.hasAuthority("ROLE_JUBILAR").then(function (result) {
            vm.isAdmin = true;
        });

        LebaniegoYearControlDonorFile.query(
            {
                id: vm.lebaniegoYearControlDonor.id
            },
            onSuccess,
            onError
        );

        function onSuccess(data, headers) {
            vm.documents = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function save() {
            vm.isSaving = true;
            var lebaniegoYearControlDonor = Object.assign({}, vm.lebaniegoYearControlDonor);
            lebaniegoYearControlDonor.gFile = {id: vm.lebaniegoYearControlDonor.gFile.id};
            LebaniegoYearControlDonor.update(lebaniegoYearControlDonor, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            if (vm.actualBusinessName != vm.lebaniegoYearControlDonor.gFile.sectorBusinessName) {
                vm.actualBusinessName = vm.lebaniegoYearControlDonor.gFile.sectorBusinessName.sectorBusinessName;
            }
            toastr.success("Actualizado con éxito!");
            vm.isSaving = false;
        }

        function onSaveError() {
            toastr.error("Error al actualizar el expediente");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                $mdSidenav(navID)
                    .toggle()
                    .then(function () {});
            };
        }

        function updateDocument(document) {
            LebaniegoYearControlDonorFile.update(document, onSaveSuccess, onSaveError);
        }

        function downloadDocument(documentId) {
            $http({
                method: "GET",
                url: "api/ly-control-files/download/" + documentId,
                responseType: "arraybuffer",
                cache: false,
            })
                .success(function (data) {
                    var a = document.createElement("a");
                    var blob = new Blob([data], {
                        type: "application/octet-stream",
                    });
                    a.href = URL.createObjectURL(blob);
                    var documentName = "";
                    for (var i = 0; i < vm.documents.length; i++) {
                        var doc = vm.documents[i];
                        if (doc.id === documentId) {
                            documentName = doc.fileName;
                        }
                    }
                    a.download = documentName;
                    a.click();
                })
                .error(function (data, status, headers, config) {
                    toastr.error("Error al descargar el documento");
                });
        }

        function showConfirm(documentId) {
            var confirm = $mdDialog
                .confirm()
                .title("¿Desea borrar el documento?")
                .textContent("Si elimina el documento no se podrá recuperar.")
                .ok("Borrar")
                .cancel("Cancelar");

            $mdDialog.show(confirm).then(
                function () {
                    removeDocument(documentId);
                },
                function () {}
            );
        }

        function removeDocument(documentId) {
            LebaniegoYearControlDonorFile.delete(
                {
                    id: documentId,
                },
                function () {
                    toastr.success("Documento eliminado con éxito");
                    LebaniegoYearControlDonorFile.query(
                        {
                            id: vm.lebaniegoYearControlDonor.id,
                        },
                        onSuccess,
                        onError
                    );
                },
                function () {
                    toastr.error("Error al eliminar el documento");
                }
            );
        }

        function uploadDocument(file, errFiles) {
            if (file) {
                Upload.upload({
                    url: "api/ly-control-files",
                    data: {
                        file: file,
                        fileName: file.name,
                        lyControlId: vm.lebaniegoYearControlDonor.id,
                    },
                    method: "POST",
                }).then(
                    function () {
                        toastr.success("Documento subido con éxito");
                        LebaniegoYearControlDonorFile.query(
                            {
                                id: vm.lebaniegoYearControlDonor.id,
                            },
                            onSuccess,
                            onError
                        );
                    },
                    function (response) {
                        toastr.error("Error al subir el documento");
                    },
                    function () {}
                );
            }
        }

        $scope.query = function(searchText) {
            return $http
            .get('/api/ly-control/g-file/search?searchText=' + searchText + '&jurisdiction=AÑO_LEBANIEGO_DONANTES')
            .then(function(data) {
                var result = data.data, deferred;
                deferred = $q.defer();
                deferred.resolve(result);
                return result;
            });
        };

        $scope.selectedItemChange = function (item) {
            if (item) {
                vm.lebaniegoYearControlDonor.businessName = item.sectorBusinessName;
                vm.lebaniegoYearControlDonor.gFile.id = item.gFileId;
            }
        }
    }
})();

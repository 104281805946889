(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller(
            "LebaniegoYearDonorNewController",
            LebaniegoYearDonorNewController
        );

    LebaniegoYearDonorNewController.$inject = [
        "$rootScope",
        "$timeout",
        "$scope",
        "entity",
        "GFile",
        "$state",
        "previousState",
        "toastr",
        "DateUtils",
        "$mdSidenav",
        "$http",
        "$q"
    ];

    function LebaniegoYearDonorNewController(
        $rootScope,
        $timeout,
        $scope,
        entity,
        GFile,
        $state,
        previousState,
        toastr,
        DateUtils,
        $mdSidenav,
        $http,
        $q
    ) {
        var vm = this;

        vm.gFile = entity;
        vm.clear = clear;
        vm.save = save;
        vm.toggleSidenav = buildToggler("left");

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            vm.gFile.jurisdiction = "AÑO_LEBANIEGO_DONANTES";
            var gFile = Object.assign({}, vm.gFile);
            if (gFile.sectorDate !== null) {
                gFile.sectorDate = DateUtils.convertLocalDateToServer(
                    gFile.sectorDate
                );
            }
            gFile.id = null;
            GFile.save(gFile, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success("Creado con éxito!");
            vm.isSaving = false;
            $state.go("lebaniego-donor.edit", {
                id: result.id,
            });
        }

        function onSaveError() {
            toastr.error("Error al crear el expediente");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                // Component lookup should always be available since we are not using `ng-if`
                $mdSidenav(navID)
                    .toggle()
                    .then(function () {});
            };
        }

        $scope.query = function(searchText) {
            return $http
            .get('/api/g-donor-files/search?searchText=' + searchText)
            .then(function(data) {
                var result = data.data, deferred;
                deferred = $q.defer();
                deferred.resolve(result);
                return result;
            });
        };

        $scope.selectedItemChange = function (item) {
            if (item) {
                vm.gFile.year = item.year;
                vm.gFile.sectorBusinessName = item.sectorBusinessName;
                vm.gFile.sectorNif = item.sectorNif;
                vm.gFile.sectorContact = item.sectorContact;
                vm.gFile.sectorAddress = item.sectorAddress;
                vm.gFile.sectorTelephone = item.sectorTelephone;
                vm.gFile.sectorEmail = item.sectorEmail;
                if (item.sectorDate !== null) {
                    vm.gFile.sectorDate = DateUtils.convertLocalDateFromServer(
                        item.sectorDate
                    );
                }
                vm.gFile.sectorDescription = item.sectorDescription;
                vm.gFile.sectorObservations = item.sectorObservations;

                $scope.searchText = '';
            }
        }
    }
})();

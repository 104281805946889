(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', '$mdSidenav'];

    function SidebarController ($state, Auth, Principal, ProfileService, $mdSidenav) {
        var vm = this;
        Principal.identity().then(function(account) {
            vm.account = account;
        });
        vm.toggleNavigation = function() {
           $mdSidenav('navigation-drawer').toggle();
         };

        vm.dropdownMenu = function(index){
         if (vm.expandMenu !== index) {
             vm.expandMenu = index;
         }
         else {
             vm.expandMenu = -1;
         }
         vm.expandSubMenu = -1;
     };
     vm.dropdownSubMenu = function(index){
         if (vm.expandSubMenu !== index) {
          vm.expandSubMenu = index;
         }
         else {
             vm.expandSubMenu = -1;
         }
     };
     vm.showSubMenu = function(index){
         return vm.expandSubMenu === index;
     };
     vm.showMenu = function(index){
         return vm.expandMenu === index;
     };
     vm.logout = function logout() {
         Auth.logout();
         $state.go('login');
     }
    }
})();

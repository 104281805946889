(function () {
    "use strict";
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular.module("expedientesUriarteApp").config(config);

    config.$inject = [
        "$mdThemingProvider",
        "$mdDateLocaleProvider",
        "toastrConfig",
    ];

    function config($mdThemingProvider, $mdDateLocaleProvider, toastrConfig) {
        $mdThemingProvider
            .theme("default")
            .primaryPalette("cyan")
            .accentPalette("teal");
        $mdThemingProvider.theme("alt");
        $mdDateLocaleProvider.firstDayOfWeek = 1;
        $mdDateLocaleProvider.formatDate = function (date) {
            var momDate = moment(date);
            return momDate.isValid() ? momDate.format("DD/MM/YYYY") : "";
        };

        angular.extend(toastrConfig, {
            positionClass: "toast-bottom-right",
        });
    }
})();

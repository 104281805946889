(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .directive('validateNiEs', validateNiEs);

        validateNiEs.$inject = ['nifValidationFactory'];

    function validateNiEs (nifValidationFactory) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, elem, iAttrs, ctrl) {
                var idTypeDoc,
                    result = false;
    
                ctrl.$parsers.unshift(checkNIEs); //Sensible to changes in DOM
                ctrl.$formatters.unshift(checkNIEs); //Sensible to changes ins Model

                function checkNIEs (value) {
                    if (value) {
                        result = nifValidationFactory.validNIF(value);
                        if (!result) {
                            result = nifValidationFactory.validNIE(value);
                        }
                        if (!result) {
                            result = nifValidationFactory.validCIF(value);
                        }
                    }
                    ctrl.$setValidity('validateNiEs', result);
    
                    return value;
                }
            }
        };
    }
})();

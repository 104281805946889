(function () {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('Graph', Graph);

    Graph.$inject = ['$resource'];

    function Graph($resource) {
        var resourceUrl = 'api/graph-:type';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();

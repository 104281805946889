(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('ConsortiumExpedienteNewController', ConsortiumExpedienteNewController);

    ConsortiumExpedienteNewController.$inject = ['$rootScope', '$timeout', '$scope', 'entity', 'ConsortiumExpediente', 'lawyers', '$state', 'previousState',  'processtypes', 'clients', 'toastr', 'DateUtils', '$mdSidenav', 'ConsortiumUsers'];

    function ConsortiumExpedienteNewController($rootScope, $timeout, $scope, entity, ConsortiumExpediente, lawyers, $state, previousState, processtypes, clients ,toastr, DateUtils, $mdSidenav, ConsortiumUsers) {
        var vm = this;

        vm.consortiumExpediente = entity;
        vm.clear = clear;
        vm.save = save;
        vm.processtypes = processtypes;
        vm.clients = clients;
        vm.lawyers = lawyers;
        vm.toggleSidenav = buildToggler('left');
        vm.consortiumExpediente.code = '';

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        ConsortiumUsers.query({}, onSuccess, onError);
        
        function onSuccess(data, headers) {
            vm.consortiumUsers = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            vm.consortiumExpediente.jurisdiction = 'ADMINISTRATIVA_CONSORCIOS';
            var consortiumExpediente = Object.assign({}, vm.consortiumExpediente);
            if (consortiumExpediente.registerDate !== null) {
                consortiumExpediente.registerDate = DateUtils.convertLocalDateToServer(consortiumExpediente.registerDate);
            }
            if (consortiumExpediente.notificationDate !== null) {
                consortiumExpediente.notificationDate = DateUtils.convertLocalDateToServer(consortiumExpediente.notificationDate);
            }
            if (consortiumExpediente.presentationDate !== null) {
                consortiumExpediente.presentationDate = DateUtils.convertLocalDateToServer(consortiumExpediente.presentationDate);
            }
            consortiumExpediente.client = vm.consortiumExpediente.client.name;
            consortiumExpediente.id = null;
            ConsortiumExpediente.save(consortiumExpediente, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success('Creado con éxito!', 'Expediente ' + result.code);
            vm.isSaving = false;
            $state.go('consortium-expedientes.edit', {
                'id': result.id
            });
        }

        function onSaveError() {
            toastr.error('Error al crear el expediente');
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function() {
              // Component lookup should always be available since we are not using `ng-if`
              $mdSidenav(navID).toggle().then(function () {
              });
              };
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('AdviceDetailController', AdviceDetailController);

    AdviceDetailController.$inject = ['$scope', '$rootScope', '$http', 'previousState', 'entity', 'Principal', 'Advice', '$state',
        'DateUtils', 'toastr', 'advicequerytypes', 'advicestatus', '$mdSidenav'];

    function AdviceDetailController($scope, $rootScope, $http, previousState, entity, Principal, Advice,  $state,
                                    DateUtils, toastr, advicequerytypes, advicestatus, $mdSidenav) {
        var vm = this;

        vm.advice = entity;
        vm.advicequerytypes = advicequerytypes;
        vm.advicestatus = advicestatus;
        vm.entityType = "AD";
        vm.save = save;
        vm.toggleSidenav = buildToggler('left');

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                vm.isAdmin = result;
            });

        function save() {
            vm.isSaving = true;

            var advice = Object.assign({}, vm.advice);
            advice.adviceType = vm.advice.adviceType.name;
            advice.adviceQueryType = vm.advice.adviceQueryType.name;
            advice.adviceStatus = vm.advice.adviceStatus.name;
            Advice.update(advice, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success('Actualizado con éxito!', 'Consulta ');
            vm.isSaving = false;
        }

        function onSaveError() {
            toastr.error('Error al actualizar la consulta');
            vm.isSaving = false;
        }

        function buildToggler(navID) {
          return function() {
            // Component lookup should always be available since we are not using `ng-if`
            $mdSidenav(navID).toggle().then(function () {
            });
            };
        }

    }
})();

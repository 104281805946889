(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller("LebaniegoYearComisionConvocatoriaNewController", LebaniegoYearComisionConvocatoriaNewController);

    LebaniegoYearComisionConvocatoriaNewController.$inject = [
        "$rootScope",
        "$timeout",
        "$scope",
        "entity",
        "LebaniegoYearComisionConvocatoria",
        "$state",
        "previousState",
        "toastr",
        "DateUtils",
        "$mdSidenav",
    ]

    function LebaniegoYearComisionConvocatoriaNewController(
        $rootScope,
        $timeout,
        $scope,
        entity,
        LebaniegoYearComisionConvocatoria,
        $state,
        previousState,
        toastr,
        DateUtils,
        $mdSidenav
    ) {
        var vm = this;

        vm.lebaniegoYearComisionConvocatoria = entity;
        vm.clear = clear;
        vm.save = save;
        vm.toggleSidenav = buildToggler("left");

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            var lebaniegoYearComisionConvocatoria = Object.assign({}, vm.lebaniegoYearComisionConvocatoria);
            lebaniegoYearComisionConvocatoria.id = null;
            LebaniegoYearComisionConvocatoria.save(lebaniegoYearComisionConvocatoria, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success("Creada con éxito!");
            vm.isSaving = false;
            $state.go("lebaniego-comission-annoucement", {});
        }

        function onSaveError() {
            toastr.error("Error al crear la convocatoria");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                $mdSidenav(navID).toggle().then(function () {});
            }
        }
    }
})();
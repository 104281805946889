(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['$scope', 'Auth', 'Principal', 'toastr',  '$mdSidenav'];

    function PasswordController($scope, Auth, Principal, toastr, $mdSidenav) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.checkStrength = checkStrength;
        vm.error = null;
        vm.success = null;
        vm.toggleSidenav = buildToggler('left');
        
        vm.onChangePassword = onChangePassword;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function checkStrength() {
            if (mesureStrength(vm.password) <= 40) {
                $scope.passwordForm.password.$invalid = true;
                $scope.passwordForm.password.$error.strengthLess = true;
                vm.strengthLess = true;
            } else {
                $scope.passwordForm.password.$invalid = false;
                $scope.passwordForm.password.$error.strengthLess = false;
                vm.strengthLess = false;
            }
        }

        function onChangePassword() {
            if (vm.password !== vm.confirmPassword) {
                $scope.passwordForm.confirmPassword.$invalid = true;
                $scope.passwordForm.confirmPassword.$error.doNotMatch = true;
                vm.doNotMatch = true;
            } else {
                $scope.passwordForm.confirmPassword.$invalid = false;
                $scope.passwordForm.confirmPassword.$error.doNotMatch = false;
                vm.doNotMatch = false;
            }
        }

        function changePassword() {
            Auth.changePassword(vm.password).then(function () {
                toastr.success('Contraseña actualizada con éxito');
                vm.error = null;
                vm.success = 'OK';
            }).catch(function () {
                toastr.success('Error al actualizar la contraseña');
                vm.success = null;
                vm.error = 'ERROR';
            });
        }
        
        function buildToggler(navID) {
            return function() {
              // Component lookup should always be available since we are not using `ng-if`
              $mdSidenav(navID).toggle().then(function () {
              });
            };
        }

        function mesureStrength(p) {

            var _force = 0;
            var _regex = /[$-/:-?{-~!"^_`\[\]]/g; // "

            var _lowerLetters = /[a-z]+/.test(p);
            var _upperLetters = /[A-Z]+/.test(p);
            var _numbers = /[0-9]+/.test(p);
            var _symbols = _regex.test(p);

            var _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];
            var _passedMatches = $.grep(_flags, function (el) {
                return el === true;
            }).length;

            _force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
            _force += _passedMatches * 10;

            // penalty (short password)
            _force = (p.length <= 6) ? Math.min(_force, 10) : _force;

            // penalty (poor variety of characters)
            _force = (_passedMatches === 1) ? Math.min(_force, 10) : _force;
            _force = (_passedMatches === 2) ? Math.min(_force, 20) : _force;
            _force = (_passedMatches === 3) ? Math.min(_force, 40) : _force;

            return _force;
        }

    }
})();

(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller(
            "ConsortiumPersonalOtraNewController",
            ConsortiumPersonalOtraNewController
        );

    ConsortiumPersonalOtraNewController.$inject = [
        "$rootScope",
        "$timeout",
        "$scope",
        "entity",
        "ConsortiumPersonalOtra",
        "$state",
        "previousState",
        "toastr",
        "DateUtils",
        "$mdSidenav",
        "ConsortiumUsers",
    ];

    function ConsortiumPersonalOtraNewController(
        $rootScope,
        $timeout,
        $scope,
        entity,
        ConsortiumPersonalOtra,
        $state,
        previousState,
        toastr,
        DateUtils,
        $mdSidenav,
        ConsortiumUsers
    ) {
        var vm = this;

        vm.consortiumPersonalOtra = entity;
        vm.clear = clear;
        vm.save = save;
        vm.toggleSidenav = buildToggler("left");

        ConsortiumUsers.query({}, onSuccess, onError);

        function onSuccess(data, headers) {
            vm.consortiumUsers = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            var consortiumPersonalOtra = Object.assign(
                {},
                vm.consortiumPersonalOtra
            );
            consortiumPersonalOtra.id = null;
            ConsortiumPersonalOtra.save(
                consortiumPersonalOtra,
                onSaveSuccess,
                onSaveError
            );
        }

        function onSaveSuccess(result) {
            toastr.success("Creado con éxito!");
            vm.isSaving = false;
            $state.go("consortium-personal-otras", {});
        }

        function onSaveError() {
            toastr.error("Error al crear otra gestión de personal");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                // Component lookup should always be available since we are not using `ng-if`
                $mdSidenav(navID)
                    .toggle()
                    .then(function () {});
            };
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('step', {
                parent: 'entity',
                url: '/step?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Steps'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/step/steps.html',
                        controller: 'StepController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jurisdictions: ['$stateParams', 'Jurisdiction', function($stateParams, Jurisdiction) {
                        return Jurisdiction.query().$promise;
                    }]
                }
            })
            .state('step-detail', {
                parent: 'entity',
                url: '/step/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Step'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/step/step-detail.html',
                        controller: 'StepDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Step', function($stateParams, Step) {
                        return Step.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'step',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('step-detail.edit', {
                parent: 'step-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/step/step-dialog.html',
                        controller: 'StepDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Step', function(Step) {
                                return Step.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, { reload: false });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('file-type.step-new', {
                parent: 'file-type',
                url: '/{fileTypeId}/step/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/step/step-dialog.html',
                        controller: 'StepDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    duration: null,
                                    id: null
                                };
                            },
                            entityfile: ['FileType','$stateParams', function(FileType,$stateParams) {
                                return FileType.get({id : $stateParams.id}).$promise;
                                //return FileType.get({id : 1}).$promise;
                            }]
                        }
                    }).result.then(
                        function() {
                            $state.go('file-type-detail', {id: $stateParams.fileTypeId});
                        }, function() {
                            $state.go('file-type');
                        });
                }]
            })
            .state('step.edit', {
                parent: 'step',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/step/step-dialog.html',
                        controller: 'StepDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Step', function(Step) {
                                return Step.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('step', null, { reload: 'step' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('step.delete', {
                parent: 'step',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/step/step-delete-dialog.html',
                        controller: 'StepDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Step', function(Step) {
                                return Step.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('step', null, { reload: 'step' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();

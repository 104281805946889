(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller("LebaniegoYearDocumentosManualNewController", LebaniegoYearDocumentosManualNewController);

        LebaniegoYearDocumentosManualNewController.$inject = [
        "$rootScope",
        "$timeout",
        "$scope",
        "entity",
        "LebaniegoYearDocumentosManual",
        "$state",
        "previousState",
        "toastr",
        "DateUtils",
        "$mdSidenav",
    ]

    function LebaniegoYearDocumentosManualNewController(
        $rootScope,
        $timeout,
        $scope,
        entity,
        LebaniegoYearDocumentosManual,
        $state,
        previousState,
        toastr,
        DateUtils,
        $mdSidenav
    ) {
        var vm = this;

        vm.lebaniegoYearDocumentosManual = entity;
        vm.clear = clear;
        vm.save = save;
        vm.toggleSidenav = buildToggler("left");

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            var lebaniegoYearDocumentosManual = Object.assign({}, vm.lebaniegoYearDocumentosManual);
            lebaniegoYearDocumentosManual.id = null;
            LebaniegoYearDocumentosManual.save(lebaniegoYearDocumentosManual, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success("Creada con éxito!");
            vm.isSaving = false;
            $state.go("lebaniego-documents-manual", {});
        }

        function onSaveError() {
            toastr.error("Error al crear el manual");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                $mdSidenav(navID).toggle().then(function () {});
            }
        }
    }
})();
(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller("LebaniegoYearControlDonorNewController", LebaniegoYearControlDonorNewController);

    LebaniegoYearControlDonorNewController.$inject = [
        "$rootScope",
        "$timeout",
        "$scope",
        "$http",
        "$state",
        "$mdSidenav",
        "$q",
        "entity",
        "LebaniegoYearControlDonor",
        "previousState",
        "toastr",
        "DateUtils",
    ]

    function LebaniegoYearControlDonorNewController(
        $rootScope,
        $timeout,
        $scope,
        $http,
        $state,
        $mdSidenav,
        $q,
        entity,
        LebaniegoYearControlDonor,
        previousState,
        toastr,
        DateUtils
    ) {
        var vm = this;

        vm.lebaniegoYearControlDonor = entity;
        vm.clear = clear;
        vm.save = save;
        vm.toggleSidenav = buildToggler("left");

        // Retrieve the value(s)
        vm.searchItem = JSON.parse(
            localStorage.getItem("searchLebaniegoYearControlDonorParameters")
        );
        
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            var lebaniegoYearControlDonor = Object.assign({}, vm.lebaniegoYearControlDonor);
            lebaniegoYearControlDonor.id = null;
            lebaniegoYearControlDonor.year = vm.lebaniegoYearControlDonor.year;
            lebaniegoYearControlDonor.sector = 'DONANTE';
            vm.lebaniegoYearControlDonor.gFile = {
                id: vm.lebaniegoYearControlDonor.gFile
            };
            lebaniegoYearControlDonor.gFile = vm.lebaniegoYearControlDonor.gFile;
            LebaniegoYearControlDonor.save(lebaniegoYearControlDonor, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success("Creada con éxito!");
            vm.isSaving = false;
            $state.go("lebaniego-control-donor", {});
        }

        function onSaveError() {
            toastr.error("Error al crear el donante");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                $mdSidenav(navID).toggle().then(function () {});
            }
        }

        $scope.query = function(searchText) {
            return $http
            .get('/api/ly-control/g-file/search?searchText=' + searchText + '&jurisdiction=AÑO_LEBANIEGO_DONANTES')
            .then(function(data) {
                var result = data.data, deferred;
                deferred = $q.defer();
                deferred.resolve(result);
                return result;
            });
        };

        $scope.selectedItemChange = function (item) {
            if (item) {
                vm.lebaniegoYearControlDonor.businessName = item.sectorBusinessName;
                vm.lebaniegoYearControlDonor.gFile = item.gFileId;
            }
        }
    }
})();
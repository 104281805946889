(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller("LebaniegoYearContactosSectorSanNewController", LebaniegoYearContactosSectorSanNewController);

    LebaniegoYearContactosSectorSanNewController.$inject = [
        "$rootScope",
        "$timeout",
        "$scope",
        "entity",
        "LebaniegoYearContactosSectorSan",
        "$state",
        "previousState",
        "toastr",
        "DateUtils",
        "$mdSidenav",
    ]

    function LebaniegoYearContactosSectorSanNewController(
        $rootScope,
        $timeout,
        $scope,
        entity,
        LebaniegoYearContactosSectorSan,
        $state,
        previousState,
        toastr,
        DateUtils,
        $mdSidenav
    ) {
        var vm = this;

        vm.lebaniegoYearContactosSectorSan = entity;
        vm.clear = clear;
        vm.save = save;
        vm.toggleSidenav = buildToggler("left");

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            var lebaniegoYearContactosSectorSan = Object.assign({}, vm.lebaniegoYearContactosSectorSan);
            lebaniegoYearContactosSectorSan.id = null;
            lebaniegoYearContactosSectorSan.sector = "Sector alimentario nacional"
            LebaniegoYearContactosSectorSan.save(lebaniegoYearContactosSectorSan, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success("Creada con éxito!");
            vm.isSaving = false;
            $state.go("lebaniego-contacts-sector-san", {});
        }

        function onSaveError() {
            toastr.error("Error al crear el acta");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                $mdSidenav(navID).toggle().then(function () {});
            }
        }
    }
})();
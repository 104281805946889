(function () {
    "use strict";

    angular.module("expedientesUriarteApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("consortium-personal-contratos", {
                parent: "entity",
                url: "/consortium-personal-contratos?page&sort&search",
                data: {
                    authorities: ["ROLE_ADMIN", "ROLE_CONSORTIUM"],
                    pageTitle: "ConsortiumPersonalContrato",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/consortium-personal/consortium-personal-contratos/consortium-personal-contratos.html",
                        controller: "ConsortiumPersonalContratoController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                },
            })
            .state("consortium-personal-contratos.new", {
                parent: "consortium-personal-contratos",
                url: "/new",
                data: {
                    authorities: ["ROLE_ADMIN"],
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/consortium-personal/consortium-personal-contratos/consortium-personal-contratos-new.html",
                        controller: "ConsortiumPersonalContratoNewController",
                        controllerAs: "vm",
                    },
                },
                ncyBreadcrumb: {
                    label: "Nuevo",
                    parent: "consortium-personal-contratos",
                },
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                        };
                    },
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name:
                                    $state.current.name ||
                                    "consortium-personal-contratos",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("consortium-personal-contratos.edit", {
                parent: "consortium-personal-contratos",
                url: "/{id}/edit",
                data: {
                    authorities: ["ROLE_ADMIN", "ROLE_CONSORTIUM"],
                    pageTitle: "ConsortiumPersonalContrato",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/consortium-personal/consortium-personal-contratos/consortium-personal-contratos-detail.html",
                        controller:
                            "ConsortiumPersonalContratoDetailController",
                        controllerAs: "vm",
                    },
                },
                ncyBreadcrumb: {
                    label: "Lista",
                    parent: "consortium-personal-contratos",
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "ConsortiumPersonalContrato",
                        function ($stateParams, ConsortiumPersonalContrato) {
                            return ConsortiumPersonalContrato.get({
                                id: $stateParams.id,
                            }).$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name:
                                    $state.current.name ||
                                    "consortium-personal-contratos",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            });
    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('AdviceNewController', AdviceNewController);

    AdviceNewController.$inject = ['$rootScope', '$timeout', '$scope', 'entity', 'Advice', '$state', 'previousState', 'advicequerytypes', 'advicestatus', 'toastr', '$mdSidenav'];

    function AdviceNewController($rootScope, $timeout, $scope, entity, Advice,  $state, previousState, advicequerytypes, advicestatus, toastr, $mdSidenav) {
        var vm = this;

        vm.advice = entity;
        vm.clear = clear;
        vm.save = save;
        vm.advicequerytypes = advicequerytypes;
        vm.advicestatus = advicestatus;
        vm.toggleSidenav = buildToggler('left');

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            vm.advice.adviceType = 'QUERY';
            var advice = Object.assign({}, vm.advice);
            advice.adviceQueryType = vm.advice.adviceQueryType.name;
            advice.adviceStatus = vm.advice.adviceStatus.name;
            advice.id = null;
            Advice.save(advice, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success('Creado con éxito!', 'Consulta');
            vm.isSaving = false;
            $state.go('advices.edit', {
                'id': result.id
            });
        }

        function onSaveError() {
            toastr.error('Error al crear la consulta');
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function() {
              // Component lookup should always be available since we are not using `ng-if`
              $mdSidenav(navID).toggle().then(function () {
            });
          };
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .component('newStep', {
            bindings: {
                stepparent: '=',
                jurisdiction: '=',
                updatetree: '=',
                newstepform: '='
            },
            templateUrl: 'app/entities/step/new-step-component.html',
            controller: ['$scope', 'Step', 'toastr',
                function ($scope, Step, toastr) {
                    var $ctrl = this;

                    $ctrl.$onInit = initialization;
                    $ctrl.save = save;
                    $ctrl.close = close;
                    $ctrl.status = [];

                    $scope.$watch('$ctrl.newstepform', function() {
                        if ($ctrl.newstepform === true) {
                            $ctrl.step = {
                                documents: false,
                                status: false
                            };
                        }
                    });

                    /**
                     * public methods
                     */
                    /**
                     * @function
                     * @name initialization
                     * @description
                     * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
                     */
                    function initialization() {
                    }

                    function save() {
                        $ctrl.isSaving = true;

                        var step = Object.assign({}, $ctrl.step);
                        step.jurisdiction = $ctrl.jurisdiction;
                        if ($ctrl.stepparent) {
                            step.stepParent = $ctrl.stepparent.id;
                        }

                        Step.save(step, onSaveSuccess, onSaveError);
                    }

                    function onSaveSuccess() {
                        toastr.success('Fase creada con éxito');
                        $ctrl.updatetree = true;
                        $ctrl.newstepform = false;
                        $ctrl.isSaving = false;
                    }

                    function onSaveError() {
                        toastr.error('Error al crear la fase');
                        $ctrl.isSaving = false;
                    }

                    function close() {
                        $ctrl.newstepform = false;
                    }

                }
            ]
        });
})();

(function() {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('GFile', GFile);

    GFile.$inject = ['$resource', 'DateUtils'];

    function GFile ($resource, DateUtils) {
        var resourceUrl =  'api/g-files/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.registerDate !== null) {
                          data.registerDate = DateUtils.convertLocalDateFromServer(data.registerDate);
                        }
                        if (data.notificationDate !== null) {
                            data.notificationDate = DateUtils.convertLocalDateFromServer(data.notificationDate);
                        }
                        if (data.presentationDate !== null) {
                            data.presentationDate = DateUtils.convertLocalDateFromServer(data.presentationDate);
                        }
                    }
                    return data;
                }
            },
            'update': { method:'PUT' ,
            transformRequest: function (data) {
              var copy = angular.copy(data);
              if (copy.registerDate !== null) {
                  copy.registerDate = DateUtils.convertLocalDateToServer(copy.registerDate);
              }
              if (copy.notificationDate !== null) {
                  copy.notificationDate = DateUtils.convertLocalDateToServer(copy.notificationDate);
              }
              if (copy.presentationDate !== null) {
                  copy.presentationDate = DateUtils.convertLocalDateToServer(copy.presentationDate);
              }
              return angular.toJson(copy);
            },
            transformResponse: function (data) {
              if (data) {
                var data = angular.fromJson(data);
                if (data.registerDate !== null) {
                  data.registerDate = DateUtils.convertLocalDateFromServer(data.registerDate);
                }
                if (data.notificationDate !== null) {
                    data.notificationDate = DateUtils.convertLocalDateFromServer(data.notificationDate);
                }
                if (data.presentationDate !== null) {
                    data.presentationDate = DateUtils.convertLocalDateFromServer(data.presentationDate);
                }
              }
              return data;
            }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                  var copy = angular.copy(data);
                  if (copy.registerDate !== null) {
                      copy.registerDate = DateUtils.convertLocalDateToServer(copy.registerDate);
                  }
                  if (copy.notificationDate !== null) {
                      copy.notificationDate = DateUtils.convertLocalDateToServer(copy.notificationDate);
                  }
                  if (copy.presentationDate !== null) {
                      copy.presentationDate = DateUtils.convertLocalDateToServer(copy.presentationDate);
                  }
                  return angular.toJson(copy);
                },
                transformResponse: function (data) {
                  if (data) {
                    var data = angular.fromJson(data);
                    if (data.registerDate !== null) {
                      data.registerDate = DateUtils.convertLocalDateFromServer(data.registerDate);
                    }
                    if (data.notificationDate !== null) {
                        data.notificationDate = DateUtils.convertLocalDateFromServer(data.notificationDate);
                    }
                    if (data.presentationDate !== null) {
                        data.presentationDate = DateUtils.convertLocalDateFromServer(data.presentationDate);
                    }
                  }
                  return data;
                }
            }
        });
    }
})();

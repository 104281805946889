(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller(
            "ConsortiumExpedienteExpropiacionDetailController",
            ConsortiumExpedienteExpropiacionDetailController
        );

    ConsortiumExpedienteExpropiacionDetailController.$inject = [
        "$mdDialog",
        "entity",
        "Principal",
        "ConsortiumExpedienteExpropiacion",
        "toastr",
        "$mdSidenav",
        "$state",
        "previousState",
        "ConsortiumExpedienteExpropiacionFile",
        "ConsortiumUsers",
        "$http",
        "Upload",
    ];

    function ConsortiumExpedienteExpropiacionDetailController(
        $mdDialog,
        entity,
        Principal,
        ConsortiumExpedienteExpropiacion,
        toastr,
        $mdSidenav,
        $state,
        previousState,
        ConsortiumExpedienteExpropiacionFile,
        ConsortiumUsers,
        $http,
        Upload
    ) {
        var vm = this;

        vm.consortiumExpedienteExpropiacion = entity;
        vm.clear = clear;

        vm.toggleSidenav = buildToggler("left");
        vm.save = save;

        vm.updateDocument = updateDocument;
        vm.downloadDocument = downloadDocument;
        vm.removeDocument = removeDocument;
        vm.uploadDocument = uploadDocument;
        vm.showConfirm = showConfirm;

        vm.updatetree = false;
        vm.nodes = [];

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        Principal.hasAuthority("ROLE_ADMIN").then(function (result) {
            vm.isAdmin = result;
        });

        ConsortiumExpedienteExpropiacionFile.query({
                id: vm.consortiumExpedienteExpropiacion.id,
            },
            onSuccess,
            onError
        );

        ConsortiumUsers.query({}, onConsortiumSuccess, onError);

        function onSuccess(data, headers) {
            vm.documents = data;
        }

        function onConsortiumSuccess(data, headers) {
            vm.consortiumUsers = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function save() {
            vm.isSaving = true;
            var consortiumExpedienteExpropiacion = Object.assign({},
                vm.consortiumExpedienteExpropiacion
            );
            ConsortiumExpedienteExpropiacion.update(
                consortiumExpedienteExpropiacion,
                onSaveSuccess,
                onSaveError
            );
        }

        function updateDocument(document) {
            ConsortiumExpedienteExpropiacionFile.update(
                document,
                onSaveSuccess,
                onSaveError
            );
        }

        function onSaveSuccess() {
            toastr.success("Guardado con éxito!");
            vm.isSaving = false;
        }

        function onSaveError() {
            toastr.error("Error al guardar");
            vm.isSaving = false;
        }

        function downloadDocument(documentId) {
            $http({
                    method: "GET",
                    url: "api/consortium-expediente-expropiacion-files/download/" +
                        documentId,
                    responseType: "arraybuffer",
                    cache: false,
                })
                .success(function (data) {
                    var a = document.createElement("a");
                    var blob = new Blob([data], {
                        type: "application/octet-stream",
                    });
                    a.href = URL.createObjectURL(blob);
                    var documentName = "";
                    for (var i = 0; i < vm.documents.length; i++) {
                        var doc = vm.documents[i];
                        if (doc.id === documentId) {
                            documentName = doc.fileName;
                        }
                    }
                    a.download = documentName;
                    a.click();
                })
                .error(function (data, status, headers, config) {
                    toastr.error("Error al descargar el documento");
                });
        }

        function showConfirm(documentId) {
            var confirm = $mdDialog
                .confirm()
                .title("¿Desea borrar el documento?")
                .textContent("Si elimina el documento no se podrá recuperar.")
                .ok("Borrar")
                .cancel("Cancelar");

            $mdDialog.show(confirm).then(
                function () {
                    removeDocument(documentId);
                },
                function () {}
            );
        }

        function removeDocument(documentId) {
            ConsortiumExpedienteExpropiacionFile.delete({
                    id: documentId,
                },
                function () {
                    toastr.success("Documento eliminado con éxito");
                    ConsortiumExpedienteExpropiacionFile.query({
                            id: vm.consortiumExpedienteOtra.id,
                        },
                        onSuccess,
                        onError
                    );
                },
                function () {
                    toastr.error("Error al eliminar el documento");
                }
            );
        }

        function uploadDocument(file, errFiles) {
            if (file) {
                Upload.upload({
                    url: "api/consortium-expediente-expropiacion-files",
                    data: {
                        file: file,
                        fileName: file.name,
                        consortiumExpedienteExpropiacionId: vm.consortiumExpedienteExpropiacion.id,
                    },
                    method: "POST",
                }).then(
                    function () {
                        toastr.success("Documento subido con éxito");
                        ConsortiumExpedienteExpropiacionFile.query({
                                id: vm.consortiumExpedienteExpropiacion.id,
                            },
                            onSuccess,
                            onError
                        );
                    },
                    function (response) {
                        toastr.error("Error al subir el documento");
                    },
                    function () {}
                );
            }
        }

        function buildToggler(navID) {
            return function () {
                // Component lookup should always be available since we are not using `ng-if`
                $mdSidenav(navID)
                    .toggle()
                    .then(function () {});
            };
        }
    }
})();

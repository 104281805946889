(function() {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .factory('ConsortiumTributosRevision', ConsortiumTributosRevision);

        ConsortiumTributosRevision.$inject = ['$resource', 'DateUtils'];

    function ConsortiumTributosRevision ($resource, DateUtils) {
        var resourceUrl =  'api/consortium-tributos-revision/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.requestDate){
                          data.requestDate = DateUtils.convertLocalDateFromServer(data.requestDate);
                        }
                        if (data.resolutionDate) {
                            data.resolutionDate = DateUtils.convertLocalDateFromServer(data.resolutionDate);
                        }
                        if (data.incomeDate) {
                            data.incomeDate = DateUtils.convertLocalDateFromServer(data.incomeDate);
                        }
                    }
                    return data;
                }
            },
            'update': {
                method:'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    if (copy.requestDate) {
                        copy.requestDate = DateUtils.convertLocalDateToServer(copy.requestDate);
                    }
                    if (copy.resolutionDate) {
                        copy.resolutionDate = DateUtils.convertLocalDateToServer(copy.resolutionDate);
                    }
                    if (copy.incomeDate) {
                        copy.incomeDate = DateUtils.convertLocalDateToServer(copy.incomeDate);
                    }
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.requestDate){
                            data.requestDate = DateUtils.convertLocalDateFromServer(data.requestDate);
                        }
                        if (data.resolutionDate) {
                            data.resolutionDate = DateUtils.convertLocalDateFromServer(data.resolutionDate);
                        }
                        if (data.incomeDate) {
                            data.incomeDate = DateUtils.convertLocalDateFromServer(data.incomeDate);
                        }
                    }
                    return data;
                }  
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    if (copy.requestDate) {
                        copy.requestDate = DateUtils.convertLocalDateToServer(copy.requestDate);
                    }
                    if (copy.resolutionDate) {
                        copy.resolutionDate = DateUtils.convertLocalDateToServer(copy.resolutionDate);
                    }
                    if (copy.incomeDate) {
                        copy.incomeDate = DateUtils.convertLocalDateToServer(copy.incomeDate);
                    }
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.requestDate){
                            data.requestDate = DateUtils.convertLocalDateFromServer(data.requestDate);
                        }
                        if (data.resolutionDate) {
                            data.resolutionDate = DateUtils.convertLocalDateFromServer(data.resolutionDate);
                        }
                        if (data.incomeDate) {
                            data.incomeDate = DateUtils.convertLocalDateFromServer(data.incomeDate);
                        }
                    }
                    return data;
                }    
            }
        });
    }
})();

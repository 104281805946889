(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('user-management', {
            parent: 'admin',
            url: '/user-management?page&sort',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Users'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/user-management/user-management.html',
                    controller: 'UserManagementController',
                    controllerAs: 'vm'
                }
            },            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }        })
        .state('user-management.new', {
            parent: 'user-management',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                  templateUrl: 'app/admin/user-management/user-management-dialog.html',
                  controller: 'UserManagementDialogController',
                  controllerAs: 'vm'
                }
            },
            ncyBreadcrumb: {
                label: 'Nuevo'
            },
            resolve: {
                entity: function () {
                    return {
                        id: null, login: null, firstName: null, lastName: null, email: null,
                        activated: true, langKey: null, createdBy: null, createdDate: null,
                        lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                        resetKey: null, authorities: null
                    };
                }
            }
        })
        .state('user-management.edit', {
            parent: 'user-management',
            url: '/{login}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                  templateUrl: 'app/admin/user-management/user-management-dialog.html',
                  controller: 'UserManagementDialogController',
                  controllerAs: 'vm'
                }
            },
            ncyBreadcrumb: {
                label: 'Editar'
            },
            resolve: {
                entity: ['User', '$stateParams', function(User, $stateParams) {
                    return User.get({login : $stateParams.login});
                }]
            }
        })
    }
})();

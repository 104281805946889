(function() {
    'use strict';
    angular
        .module('expedientesUriarteApp')
        .directive('docExistValid', docExistValid);

    docExistValid.$inject = ['ThirdParty', '$q'];

    /**
     * Search a third party with same document
     */
    function searchDocument(modelValue, viewValue){
        if (angular.isUndefined(viewValue)){
            return true;
        }

        ThirdParty.get({id: '/nif/' + viewValue.toUpperCase()},
            function(data) {
                return angular.isUndefined(data);
            });

        return false;
    }

    function docExistValid(ThirdParty,$q) {
        return {
            require: "ngModel",
            link: function (scope, elem, attrs, ngModel) {
                ngModel.$asyncValidators.docExist = function (modelValue, viewValue){
                    var deferred  = $q.defer();
                    $q.all([
                      ThirdParty.query({
                             nif: viewValue
                           }).$promise]
                    ).then(function(data){
                      var result = data[0];
                       if (result.length === 1) {
                         ngModel.$setValidity('docExist', true);
                         deferred.reject(false);
                       } else {
                         ngModel.$setValidity('docExist', false);
                         deferred.resolve(true);
                       }
                    });
                    return deferred.promise;
                };
            }

        };
    }

})();

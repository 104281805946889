(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('ReportsDetailController', ReportsDetailController);

    ReportsDetailController.$inject = ['$scope', '$rootScope', '$http', 'previousState', 'entity', 'Principal', 'Advice', '$state',
        'DateUtils', 'toastr', 'advicestatus', '$mdSidenav'];

    function ReportsDetailController($scope, $rootScope, $http, previousState, entity, Principal, Advice,  $state,
                                    DateUtils, toastr, advicestatus, $mdSidenav) {
        var vm = this;

        vm.report = entity;
        vm.advicestatus = advicestatus;
        vm.entityType = "AD";
        vm.save = save;
        vm.toggleSidenav = buildToggler('left');

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                vm.isAdmin = result;
            });

        function save() {
            vm.isSaving = true;

            var report = Object.assign({}, vm.report);
            report.adviceType = vm.report.adviceType.name;
            report.adviceStatus = vm.report.adviceStatus.name;
            Advice.update(report, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success('Actualizado con éxito!', 'Consulta ');
            vm.isSaving = false;
        }

        function onSaveError() {
            toastr.error('Error al actualizar la consulta');
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function() {
              // Component lookup should always be available since we are not using `ng-if`
              $mdSidenav(navID).toggle().then(function () {
              });
            };
          }
    }
})();

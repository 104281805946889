(function () {
    "use strict";
    angular
        .module("expedientesUriarteApp")
        .factory(
            "ConsortiumContabilidadEmitida",
            ConsortiumContabilidadEmitida
        );

    ConsortiumContabilidadEmitida.$inject = ["$resource", "DateUtils"];

    function ConsortiumContabilidadEmitida($resource, DateUtils) {
        var resourceUrl = "api/consortium-contabilidad-emitidas/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.invoiceDate) {
                                data.invoiceDate = DateUtils.convertLocalDateFromServer(
                                    data.invoiceDate
                                );
                            }
                            if (data.receiveDate) {
                                data.receiveDate = DateUtils.convertLocalDateFromServer(
                                    data.receiveDate
                                );
                            }
                        }
                        return data;
                    },
                },
                update: {
                    method: "PUT",
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        if (copy.invoiceDate !== null) {
                            copy.invoiceDate = DateUtils.convertLocalDateToServer(
                                copy.invoiceDate
                            );
                        }
                        if (copy.receiveDate !== null) {
                            copy.receiveDate = DateUtils.convertLocalDateToServer(
                                copy.receiveDate
                            );
                        }
                        return angular.toJson(copy);
                    },
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.invoiceDate) {
                                data.invoiceDate = DateUtils.convertLocalDateFromServer(
                                    data.invoiceDate
                                );
                            }
                            if (data.receiveDate) {
                                data.receiveDate = DateUtils.convertLocalDateFromServer(
                                    data.receiveDate
                                );
                            }
                        }
                        return data;
                    },
                },
                save: {
                    method: "POST",
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        if (copy.invoiceDate !== null) {
                            copy.invoiceDate = DateUtils.convertLocalDateToServer(
                                copy.invoiceDate
                            );
                        }
                        if (copy.receiveDate !== null) {
                            copy.receiveDate = DateUtils.convertLocalDateToServer(
                                copy.receiveDate
                            );
                        }
                        return angular.toJson(copy);
                    },
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            if (data.invoiceDate) {
                                data.invoiceDate = DateUtils.convertLocalDateFromServer(
                                    data.invoiceDate
                                );
                            }
                            if (data.receiveDate) {
                                data.receiveDate = DateUtils.convertLocalDateFromServer(
                                    data.receiveDate
                                );
                            }
                        }
                        return data;
                    },
                },
            }
        );
    }
})();

(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller("LebaniegoYearDonorController", LebaniegoYearDonorController);

        LebaniegoYearDonorController.$inject = [
        "DateUtils",
        "$state",
        "LebaniegoYearDonor",
        "ParseLinks",
        "AlertService",
        "paginationConstants",
        "pagingParams",
        "$mdSidenav"
    ];

    function LebaniegoYearDonorController(
        DateUtils,
        $state,
        LebaniegoYearDonor,
        ParseLinks,
        AlertService,
        paginationConstants,
        pagingParams,
        $mdSidenav
    ) {
        var vm = this;

        vm.searchExp = searchExp;
        vm.deleteSearchItem = deleteSearchItem;

        vm.toggleSidenav = buildToggler("left");

        // Retrieve the value(s)
        vm.searchItem = JSON.parse(localStorage.getItem("searchParameters"));

        if (vm.searchItem === null) {
            vm.searchItem = {};
            vm.searchItem.year = new Date().getFullYear();
        }

        if (vm.searchItem.startDate !== null) {
            vm.searchItem.startDate = DateUtils.convertLocalDateFromServer(
                vm.searchItem.startDate
            );
        }

        if (vm.searchItem.endDate !== null) {
            vm.searchItem.endDate = DateUtils.convertLocalDateFromServer(
                vm.searchItem.endDate
            );
        }

        vm.status = status;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        searchExp();

        function searchExp() {
            if (vm.searchItem.startDate !== null)
                vm.searchItem.startDate = DateUtils.convertLocalDateToServer(
                    vm.searchItem.startDate
                );
            if (vm.searchItem.endDate !== null)
                vm.searchItem.endDate = DateUtils.convertLocalDateToServer(
                    vm.searchItem.endDate
                );
            localStorage.setItem(
                "searchParameters",
                JSON.stringify(vm.searchItem)
            );

            LebaniegoYearDonor.query(
                {
                    year: vm.searchItem.year,
                    businessName: vm.searchItem.businessName,
                    nif: vm.searchItem.nif,
                    jurisdiction: 'AÑO_LEBANIEGO_DONANTES',
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                },
                onSuccess,
                onError
            );

            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    result.push("id");
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.gFiles = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function deleteSearchItem() {
            vm.searchItem = {};
            vm.searchItem.year = new Date().getFullYear();
            vm.searchItem.status = status[0];

            localStorage.setItem(
                "searchParameters",
                JSON.stringify(vm.searchItem)
            );
            vm.page = 1;
            vm.transition();
            searchExp();
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: vm.currentSearch,
            });
        }

        function buildToggler(navID) {
            return function () {
                // Component lookup should always be available since we are not using `ng-if`
                $mdSidenav(navID)
                    .toggle()
                    .then(function () {});
            };
        }

    }
})();

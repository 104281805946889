(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller("GFileContenciosoController", GFileContenciosoController);

    GFileContenciosoController.$inject = [
        "DateUtils",
        "$state",
        "GFile",
        "GFileCsv",
        "ParseLinks",
        "AlertService",
        "paginationConstants",
        "pagingParams",
        "steps",
        "status",
        "$mdSidenav",
    ];

    function GFileContenciosoController(
        DateUtils,
        $state,
        GFile,
        GFileCsv,
        ParseLinks,
        AlertService,
        paginationConstants,
        pagingParams,
        steps,
        status,
        $mdSidenav
    ) {
        var vm = this;

        vm.searchExp = searchExp;
        vm.deleteSearchItem = deleteSearchItem;

        vm.toggleSidenav = buildToggler("left");

        // Retrieve the value(s)
        vm.searchItem = JSON.parse(localStorage.getItem("searchParameters"));

        if (vm.searchItem === null) {
            vm.searchItem = {};
            vm.searchItem.year = new Date().getFullYear();
            vm.searchItem.status = status[0];
        }

        if (vm.searchItem.startDate !== null) {
            vm.searchItem.startDate = DateUtils.convertLocalDateFromServer(
                vm.searchItem.startDate
            );
        }

        if (vm.searchItem.endDate !== null) {
            vm.searchItem.endDate = DateUtils.convertLocalDateFromServer(
                vm.searchItem.endDate
            );
        }

        vm.status = status;
        vm.steps = steps;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.csvOrder = [
            "code",
            "lawyer",
            "client",
            "applicant",
            "applicantNif",
            "procuratorApplicant",
            "defendant",
            "defendantNif",
            "procuratorDefendant",
            "court",
            "processType",
            "step",
            "status",
        ];
        vm.getHeader = function () {
            return [
                "Código",
                "Letrado",
                "Cliente",
                "Demandante",
                "NIF Demandante",
                "Procurador demandante",
                "Demandado",
                "NIF Demandado",
                "Procurador demandado",
                "Juzgado",
                "Procedimiento",
                "Fase",
                "Estado",
            ];
        };

        searchExp();

        function searchExp() {
            // Store the value(s)
            if (vm.searchItem.startDate !== null)
                vm.searchItem.startDate = DateUtils.convertLocalDateToServer(
                    vm.searchItem.startDate
                );
            if (vm.searchItem.endDate !== null)
                vm.searchItem.endDate = DateUtils.convertLocalDateToServer(
                    vm.searchItem.endDate
                );
            localStorage.setItem(
                "searchParameters",
                JSON.stringify(vm.searchItem)
            );

            var idStep = null;
            if (angular.isDefined(vm.searchItem.step)) {
                idStep = vm.searchItem.step.id;
            }

            var idStatus = null;
            if (
                angular.isDefined(vm.searchItem.status) &&
                vm.searchItem.status != null
            ) {
                idStatus = vm.searchItem.status.id;
            }

            GFile.query(
                {
                    code: vm.searchItem.code,
                    status: idStatus,
                    client: vm.searchItem.client,
                    startDate: vm.searchItem.startDate,
                    endDate: vm.searchItem.endDate,
                    jurisdiction: "CONTENCIOSA",
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                },
                onSuccess,
                onError
            );

            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    result.push("id");
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.gFiles = data;
                vm.page = pagingParams.page;

                if (vm.searchItem.startDate !== null)
                    vm.searchItem.startDate =
                        DateUtils.convertLocalDateFromServer(
                            vm.searchItem.startDate
                        );
                if (vm.searchItem.endDate !== null)
                    vm.searchItem.endDate =
                        DateUtils.convertLocalDateFromServer(
                            vm.searchItem.endDate
                        );

                vm.filename = getFilename();
                getExportData();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getFilename() {
            var today = new Date();

            var dd = today.getDate();
            if (dd < 10) dd = "0" + dd;

            var mm = today.getMonth() + 1;
            if (mm < 10) mm = "0" + mm;

            var yy = today.getFullYear();

            return "export_expedientes_contencioso_" + yy + mm + dd + ".csv";
        }

        function deleteSearchItem() {
            vm.searchItem = {};
            vm.searchItem.year = new Date().getFullYear();
            vm.searchItem.status = status[0];

            localStorage.setItem(
                "searchParameters",
                JSON.stringify(vm.searchItem)
            );
            vm.page = 1;
            vm.transition();
            searchExp();
        }

        function getExportData() {
            var idFileType = null;
            if (angular.isDefined(vm.searchItem.fileType)) {
                idFileType = vm.searchItem.fileType.id;
            }

            var idStep = null;
            if (angular.isDefined(vm.searchItem.step)) {
                idStep = vm.searchItem.step.id;
            }

            var idStatus = null;
            if (
                angular.isDefined(vm.searchItem.status) &&
                vm.searchItem.status != null
            ) {
                idStatus = vm.searchItem.status.id;
            }

            if (vm.searchItem.startDate !== null)
                vm.searchItem.startDate = DateUtils.convertLocalDateToServer(
                    vm.searchItem.startDate
                );
            if (vm.searchItem.endDate !== null)
                vm.searchItem.endDate = DateUtils.convertLocalDateToServer(
                    vm.searchItem.endDate
                );

            GFileCsv.query(
                {
                    code: vm.searchItem.code,
                    year: vm.searchItem.year,
                    status: idStatus,
                    step: idStep,
                    startDate: vm.searchItem.startDate,
                    endDate: vm.searchItem.endDate,
                    jurisdiction: "CONTENCIOSA",
                },
                function (data) {
                    vm.exportData = data;
                    vm.exportData.forEach(function (item) {
                        if (item.processType) {
                            item.processType = item.processType.description;
                        }
                        if (item.client) {
                            item.client = item.client.description;
                        }
                    });
                }
            );
        }

        if (angular.isDefined(vm.searchItem.fileType)) {
            Step.query(
                {
                    file_type_id: vm.searchItem.fileType.id,
                },
                function (data) {
                    vm.steps = data;
                }
            );
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: vm.currentSearch,
            });
        }

        function buildToggler(navID) {
            return function () {
                // Component lookup should always be available since we are not using `ng-if`
                $mdSidenav(navID)
                    .toggle()
                    .then(function () {});
            };
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('AdvicesController', AdvicesController);

    AdvicesController.$inject = ['DateUtils', '$state', 'Advice', 'AdviceCsv', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','status', '$mdSidenav'];

    function AdvicesController (DateUtils, $state, Advice, AdviceCsv, ParseLinks, AlertService, paginationConstants, pagingParams, status, $mdSidenav) {
        var vm = this;

        vm.searchExp = searchExp;
        vm.deleteSearchItem = deleteSearchItem;
    
        vm.toggleSidenav = buildToggler('left');

        // Retrieve the value(s)
        vm.searchItem = JSON.parse(localStorage.getItem('searchAdviceParameters'));

        if (vm.searchItem === null) {
          vm.searchItem = {};
          vm.searchItem.year = new Date().getFullYear();
          vm.searchItem.status = status[0];
        }

        if (vm.searchItem.startDate !== null) {
            vm.searchItem.startDate = DateUtils.convertLocalDateFromServer(vm.searchItem.startDate);
        }

        if (vm.searchItem.endDate !== null) {
            vm.searchItem.endDate = DateUtils.convertLocalDateFromServer(vm.searchItem.endDate);
        }

        vm.status = status;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.csvOrder = ["caseFileId", "adviceQueryType", "requestDate", "applicantName", "applicantNif", "background", "resolution",  "resolutionDate", "adviceStatus"];
        vm.getHeader = function () {
            return ["Expediente", "Tipo", "Fecha de solicitud", "Solicitante", "NIF Solicitante", "antecedentes", "Resolución", "Fecha de resolución", "Estado"];
        };

        searchExp();

        function searchExp() {
            // Store the value(s)
            if(vm.searchItem.startDate !== null) vm.searchItem.startDate = DateUtils.convertLocalDateToServer(vm.searchItem.startDate);
            if(vm.searchItem.endDate !== null) vm.searchItem.endDate = DateUtils.convertLocalDateToServer(vm.searchItem.endDate);
            localStorage.setItem('searchAdviceParameters', JSON.stringify(vm.searchItem));

            var idStatus = null;
            if (angular.isDefined(vm.searchItem.status) && vm.searchItem.status != null) {
                idStatus = vm.searchItem.status.name;
            }

            Advice.query({
                type: 'QUERY',
                caseFile: vm.searchItem.caseFile,
                status: idStatus,
                client: vm.searchItem.client,
                startDate: vm.searchItem.startDate,
                endDate: vm.searchItem.endDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.advices = data;
                vm.page = pagingParams.page;

                if(vm.searchItem.startDate !== null) vm.searchItem.startDate = DateUtils.convertLocalDateFromServer(vm.searchItem.startDate);
                if(vm.searchItem.endDate !== null) vm.searchItem.endDate = DateUtils.convertLocalDateFromServer(vm.searchItem.endDate);

                vm.filename = getFilename();
                getExportData();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getFilename() {
          var today = new Date();

          var dd = today.getDate();
          if(dd < 10) dd = "0" + dd;

          var mm = today.getMonth()+1;
          if(mm < 10) mm = "0" + mm;

          var yy = today.getFullYear();

            return "export_consultas_verbales_escritas_" + yy + mm + dd + ".csv";
        }

        function deleteSearchItem() {
          vm.searchItem = {};
          vm.searchItem.year = new Date().getFullYear();
          vm.searchItem.status = status[0];

          localStorage.setItem('searchAdviceParameters', JSON.stringify(vm.searchItem));
          vm.page = 1;
          vm.transition();
          searchExp();
        }

        function getExportData(){
          var idFileType = null;
          if (angular.isDefined(vm.searchItem.fileType) ) {
            idFileType = vm.searchItem.fileType.id;
          }

          var idStep = null;
          if (angular.isDefined(vm.searchItem.step) ) {
              idStep = vm.searchItem.step.id;
          }

          var idStatus = null;
            if (angular.isDefined(vm.searchItem.status) && vm.searchItem.status != null) {
              idStatus = vm.searchItem.status.id;
          }

          if(vm.searchItem.startDate !== null) vm.searchItem.startDate = DateUtils.convertLocalDateToServer(vm.searchItem.startDate);
          if(vm.searchItem.endDate !== null) vm.searchItem.endDate = DateUtils.convertLocalDateToServer(vm.searchItem.endDate);

          AdviceCsv.query({
            type: 'QUERY',
            caseFile: vm.searchItem.caseFile,
            status: idStatus,
            client: vm.searchItem.client,
            startDate: vm.searchItem.startDate,
            endDate: vm.searchItem.endDate
          }, function(data) {
              vm.exportData = data;
              vm.exportData.forEach(function(item) {
                item.adviceStatus = item.adviceStatus.description;
                item.adviceQueryType = item.adviceQueryType.description;
              });
          });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function buildToggler(navID) {
          return function() {
            // Component lookup should always be available since we are not using `ng-if`
            $mdSidenav(navID).toggle().then(function () {
          });
        };
      }
    }
})();

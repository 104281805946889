(function () {
    "use strict";

    angular.module("expedientesUriarteApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("lebaniego-contacts-visit", {
                parent: "entity",
                url: "/lebaniego-contacts-visit?page&sort&search",
                data: {
                    authorities: ["ROLE_ADMIN", "ROLE_JUBILAR"],
                    pageTitle: "LebaniegoYearContactosVisita",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/lebaniego/lebaniego-contactos/lebaniego-contactos-visita/lebaniego-contactos-visita.html",
                        controller:
                            "LebaniegoYearContactosVisitaController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                },
            })
            .state("lebaniego-contacts-visit.new", {
                parent: "lebaniego-contacts-visit",
                url: "/new",
                data: {
                    authorities: ["ROLE_ADMIN", "ROLE_JUBILAR"]
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/lebaniego/lebaniego-contactos/lebaniego-contactos-visita/lebaniego-contactos-visita-new.html",
                        controller: "LebaniegoYearContactosVisitaNewController",
                        controllerAs: "vm"
                    }
                },
                ncyBreadcrumb: {
                    label: "Nueva",
                    parent: "lebaniego-contacts-visit"
                },
                resolve: {
                    entity: function() {
                        return {
                            id: null
                        };
                    },
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "lebaniego-contacts-visit",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                )
                            };
                            return currentStateData;
                        }
                    ]
                }
            })
            .state("lebaniego-contacts-visit.edit", {
                parent: "lebaniego-contacts-visit",
                url: "/{id}/edit",
                data: {
                    authorities: ["ROLE_ADMIN", "ROLE_JUBILAR"],
                    pageTitle: "LebaniegoYearContactosVisita"
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/lebaniego/lebaniego-contactos/lebaniego-contactos-visita/lebaniego-contactos-visita-detail.html",
                        controller: "LebaniegoYearContactosVisitaDetailController",
                        controllerAs: "vm"
                    }
                },
                ncyBreadcrumb: {
                    label: "Lista",
                    parent: "lebaniego-contacts-visit"
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "LebaniegoYearContactosVisita",
                        function ($stateParams, LebaniegoYearContactosVisita) {
                            return LebaniegoYearContactosVisita.get({
                                id: $stateParams.id
                            }).$promise;
                        }
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "lebaniego-contacts-visit",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                )
                            };
                            return currentStateData;
                        }
                    ]
                }
            });
    }
})();
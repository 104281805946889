(function () {
    "use strict";

    angular
        .module("expedientesUriarteApp")
        .controller("LebaniegoYearContactosVisitaNewController", LebaniegoYearContactosVisitaNewController);

        LebaniegoYearContactosVisitaNewController.$inject = [
        "$rootScope",
        "$timeout",
        "$scope",
        "entity",
        "LebaniegoYearContactosVisita",
        "$state",
        "previousState",
        "toastr",
        "DateUtils",
        "$mdSidenav",
    ]

    function LebaniegoYearContactosVisitaNewController(
        $rootScope,
        $timeout,
        $scope,
        entity,
        LebaniegoYearContactosVisita,
        $state,
        previousState,
        toastr,
        DateUtils,
        $mdSidenav
    ) {
        var vm = this;

        vm.lebaniegoYearContactosVisita = entity;
        vm.clear = clear;
        vm.save = save;
        vm.toggleSidenav = buildToggler("left");
        vm.sectors = ["Bebidas y alimentación de Cantabria", "Sector alimentario nacional", "Sector bebidas nacionales", "Sector fabricantes de aceite", "Empresas distribución", "Otros sectores"];

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function save() {
            vm.isSaving = true;
            var lebaniegoYearContactosVisita = Object.assign({}, vm.lebaniegoYearContactosVisita);
            lebaniegoYearContactosVisita.id = null;
            LebaniegoYearContactosVisita.save(lebaniegoYearContactosVisita, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success("Creada con éxito!");
            vm.isSaving = false;
            $state.go("lebaniego-contacts-visit", {});
        }

        function onSaveError() {
            toastr.error("Error al crear la visita");
            vm.isSaving = false;
        }

        function buildToggler(navID) {
            return function () {
                $mdSidenav(navID).toggle().then(function () {});
            }
        }
    }
})();
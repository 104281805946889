(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService','$rootScope','$mdSidenav'];

    function NavbarController($state, Auth, Principal, ProfileService, LoginService, $rootScope,$mdSidenav) {
        var vm = this;
        $rootScope.sidebarToogled = false;
        vm.changeSidebar = function() {
            $rootScope.sidebarToogled = !$rootScope.sidebarToogled;
        };
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        Principal.identity().then(function(account) {
            vm.account = account;
        });
        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.toggleSidenav = buildToggler('left');

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function buildToggler(navID) {
          return function() {
            // Component lookup should always be available since we are not using `ng-if`
            $mdSidenav(navID).toggle().then(function () {
            });
          };
        }
    }
})();

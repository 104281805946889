(function() {
    'use strict';

    angular
        .module('expedientesUriarteApp')
        .component('editGfileStep', {
            bindings: {
                gfilestep: '=',
                editstepform: '=',
                updatetree: '=',
                isdeletedstep: '<'
            },
            templateUrl: 'app/entities/g-file-step/edit-g-file-step-component.html',
            controller: ['$scope', 'StepStatus','DateUtils', 'GFileStep', 'toastr',
                function ($scope, StepStatus, DateUtils, GFileStep, toastr) {
                    var $ctrl = this;

                    $ctrl.$onInit = initialization;
                    $ctrl.save = save;
                    $ctrl.close = close;
                    $ctrl.remove_gstep = remove_gstep;

                    function initialization() {

                    }

                    $scope.$watch('$ctrl.gfilestep.step', function() {
                      if ($ctrl.gfilestep.step && $ctrl.gfilestep.step.status){
                        StepStatus.query({
                            stepId: $ctrl.gfilestep.step.id
                        })
                            .$promise
                            .then(function(data) {
                              $ctrl.status = data;
                            });
                      }
                    });

                    function save() {
                        $ctrl.isSaving = true;
                        var gFile = {"id": $ctrl.gfilestep.gfile.id};
                        var gFileStep = Object.assign({}, $ctrl.gfilestep);
                        gFileStep.actions = undefined;
                        gFileStep.gfile = gFile;
                        GFileStep.update(gFileStep, onSaveSuccess, onSaveError);
                    }

                    function onSaveSuccess(result) {
                        toastr.success('Actualizada con éxito!', 'Fase ' + result.step.name);

                        $ctrl.editstepform = false;
                        $ctrl.isSaving = false;
                    }

                    function onSaveError() {
                        toastr.error('Error al actualizar la fase');
                        $ctrl.isSaving = false;
                    }

                    function close() {
                        $ctrl.editstepform = false;
                    }

                    function remove_gstep() {
                        GFileStep.delete({
                                id: $ctrl.gfilestep.id
                            },
                            function() {
                                toastr.success('Fase eliminada con éxito');
                                $ctrl.updatetree = true;
                                $ctrl.editstepform = false;
                                $ctrl.isdeletedstep = false;
                            },
                            function () {
                                toastr.error('No puede eliminarse una fase con actuaciones');
                            });
                    }

                }
            ]
        });
})();
